<!-- Breadcrumb -->
<div class="my-shade-custom" *ngIf="isLoadingResultsPlans">
  <div class="spinner-container">
    <div class="loadGifCustom"></div>
    <img class="spin-image" [src]="logo" alt="spinner" width="100px">
  </div>
</div>

<!-- <div class="info-container">
  <div
    (click)="openDialog(pageInfo?.title)"
    class="cursor-pointer info-fix-custom"
    *ngIf="currentUser.role != 'Administrator'"
  >
    <img src="assets/images/topbr-icons/info-fixed.svg" alt="" />
  </div>
</div> -->
<div class="page-breadcrumb p-t-20 p-b-20 p-l-30 p-r-30">
  <div fxLayout="row wrap">
    <div fxFlex.gt-sm="50" fxFlex.gt-xs="50" fxFlex="100" class="align-self-center"></div>
    <div *ngIf="currentUser.role != 'Administrator'" fxFlex.gt-sm="50" fxFlex.gt-xs="50" fxFlex="100"
      class="align-self-center text-center"></div>
  </div>
</div>

<div *ngIf="showAlertPlan" class="plan-alert">
  <div class="modal-pop-up" *ngFor="let chip of alertPlan | slice:0:1">
    <img class="x-image" src="assets/images/new-card-icons/X-button-big.svg" alt="">
    <div class="modal-title">{{('FunctionBlocked' | translate)}}</div>
    <div class="modal-text">{{chip.name}}</div>
    <div class="button-section">
      <button class="pop-up-button blue-default" (click)="closeModal()">
        {{('Close' | translate)}}
      </button>
      <button class="pop-up-button green-active" (click)="changeView(chip.state)">
        {{('PayPlan' | translate)}}
      </button>
    </div>
  </div>
</div>

<div *ngIf="showAlert" class="p-t-20 p-b-20 p-l-30 p-r-30" style="padding-top: 20">
  <div fxLayout="row wrap" class="align-items-center">
    <div *ngFor="let chip of availableColors" fxFlex="100" fxFlex.gt-md="100">
      <button (click)="changeView(chip.state)" mat-raised-button class="m-r-10 bg-warning" color="{{ chip.color }}"
        style="margin-bottom: 10px; white-space: normal; width: 100%">
        {{ chip.name }}
      </button>
    </div>
  </div>
</div>