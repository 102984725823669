<div class="custom-modal">
  <div class="left-part">
    <div class="image-container">
      <img src="assets/images/new-card-icons/dashboard-supplier-graphics.svg" alt="">
    </div>
  </div>
  <div class="right-part">
    <div class="title">
        {{'ERegistrationStepper.ModalGlobalTitle' | translate}}
    </div>
    <div class="content">
      <div class="text">
        <div class="flex-inner-content">
          {{'ERegistrationStepper.ModalGlobalText' | translate}}
        </div>

      </div>


    </div>
    <div class="button-container">

      <div class="button-card-custom blue-default" (click)="goToProfile()">
      {{'ERegistrationStepper.UpdateProfile' | translate}}
      </div>

      <div class="link-custom-new" id="jump-off"  (click)="jumpOff()">{{'ERegistrationStepper.JumpAndCompile' | translate}}
      </div>

    </div>
  </div>
  </div>
