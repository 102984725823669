import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Helper } from 'src/helper/helper';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})

export class VerticalHeaderService {
  
  header: any;
  onDataChanged: Subject<any>;
  constructor(private _http: HttpClient, private _helper: Helper) {
    this.onDataChanged = new Subject();
  }





  getChatNewMessages(): Promise<any> {
    this.setHeader();
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'conversations/getChatsUnreads', this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  getAllNotifications(): Promise<any> {
    this.setHeader();
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'notifications/getNotifications', this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  updateLanguage(lang: string): Promise<any> {
    this.setHeader();
    return new Promise((resolve, reject) => {
      this._http.post(this._helper.getBaseUrl() + 'auth/updateLanguage', { language: lang }, this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  getRefreshToken(token : any): Promise<any> {
    this.setHeader();
    return new Promise((resolve, reject) => {
      this._http.post(this._helper.getBaseUrl() + 'auth/refresh', {token : token, extend_session: 0}, this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }


  getAssociation(): Promise<any> {
    this.setHeader();
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'associations/listAssociation', this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  // getNumberNeedResourceClosed(): Promise<any> {
  //   this.setHeader();
  //   return new Promise((resolve, reject) => {
  //     this._http.get(this._helper.getBaseUrl() + 'client/need/getNumberNeedResourceClosed', this.header)
  //       .subscribe((response: any) => {
  //         resolve(response);
  //       }, reject);
  //   });
  // }

  getStatusCompletedCompanyProfile(): Promise<any> {
    this.setHeader();
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'company/getStatusCompletedCompanyProfile', this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  // getProjectsActive(): Promise<any> {
  //   this.setHeader();
  //   return new Promise((resolve, reject) => {
  //     this._http.get(this._helper.getBaseUrl() + 'client/need/getProjectsActive', this.header)
  //       .subscribe((response: any) => {
  //         resolve(response);
  //       }, reject);
  //   });
  // }

  getFunctionCompany(): Promise<any> {
    this.setHeader();
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'functions/getFunctionCompany', this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  getDocumentationStatus(): Promise<any> {
    this.setHeader();
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'company/getDocumentationStatus', this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  getParamLogin(token: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'auth/getParamLogin?token=' + token)
        .subscribe((response: any) => {
          resolve(response);
        });
    });
  }
  

  setHeader() {
    this.header = {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${this._helper.getUserConfigData().access_token}`)
    };
  }

}
