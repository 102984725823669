import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Helper } from 'src/helper/helper';

@Injectable({
  providedIn: 'root'
})

export class AppComponentService {
  header: any;

  constructor(private _http: HttpClient, private _helper: Helper) {

  }
  getBrandColors(url:any): Promise<any> {

    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'auth/getBrand?url=' + url)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  getLogoCompany(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'auth/getLogo')
        .subscribe((response: any) => {
          resolve(response);
        });
    });
  }


}
