<div class="custom-modal">
  <div class="menu-container">
    <div class="title">
      {{('SessionExpiredModal.Title' | translate) }}
    </div>

    <form #userForm="ngForm">
      <div class="content">
        <div class="text">
          {{('SessionExpiredModal.Description' | translate) }} {{counterStart}}  {{('SessionExpiredModal.Description1' | translate) }}
        </div>

        <div class="buttons">
          <button class="button-medium blue-default" (click)="closeDialog()">
            Logout
          </button>
          <button class="button-medium dark-blue-default " (click)="extendSession()">
            {{('SessionExpiredModal.ExtendSession' | translate) }}
           </button>
        </div>
      </div>
    </form>
  </div>
