import { Injectable, Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Pipe({ name: "highlighter" })
@Injectable()
export class MadPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {
  }

 /*
  The transform method is the core part of a custom Pipe.
  It takes three parameters:
   - originalText: the text that contains the string to be searched for
   - textToFind: the text to be found
   - cssClass: a styling class to highlight the text (default value: 'highlighter')
 */
 transform(
  originalText: string,
  textToFind:any,
  cssClass: string = 'highlighter'
 ): SafeHtml {
  // Check the parameters, if something is missing we simply return the original text.
  if (typeof originalText !== 'string' || !textToFind) {
   return this.domSanitizer.bypassSecurityTrustHtml(originalText);
  }

  /*
   We create a pattern based on the `textToFind` value.
   We replace some special characters in the string with their escape sequences.
   Then we split the string by spaces and remove empty elements.
   Finally, we join the remaining elements using '|' as the separator.
  */
  const pattern = textToFind
   .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
   .split(' ')
   .filter((t:any) => t.length > 0)
   .join('|');


  /*
   Create a regex (regular expression) based on the pattern created above.
   The `gi` flag means "global" (all matches) and "case-insensitive".
  */
  const regex = new RegExp(pattern, 'gi');

  /*
   If `textToFind` exists, replace all the matches with a span element that has the specified css class.
   If `textToFind` doesn't exist, return the original text.
  */
  let result = textToFind
   ? this.domSanitizer.bypassSecurityTrustHtml(originalText.replace(
    regex,
    (match) => `<span class=${cssClass}">${match}</span>`
   ))
   : this.domSanitizer.bypassSecurityTrustHtml(originalText);

  return result;
 }
}
