<div class="custom-modal">
  <div class="menu-container">
    <form #userForm="ngForm">
      <div class="tutorial-pop-up">
        <div class="title-tutorial">
          <div class="title-text-tutorial">
            {{ !data.description ? ('DescriptionPages' | translate) : data.title
            }}
          </div>
          <div class="close-me" (click)="closeDialog()">
            <img src="assets/images/new-card-icons/X-blue.svg" alt="X" />
          </div>
        </div>
        <div class="tutorial-desc">
          {{!data.description ? ('InformationNotFound' | translate) :
          data.description}}
        </div>
      </div>
    </form>
  </div>
</div>
