<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container" [dir]="dir"
  [ngClass]="{'minisidebar': minisidebar, 'boxed': boxed, 'danger': danger, 'blue': blue, 'green': green, 'dark': dark,  'horizontal' : horizontal }">
  <!-- ============================================================== -->
  <!-- Topbar - style you can find in header.scss -->
  <!-- ============================================================== -->

  <!-- ============================================================== -->
  <!-- Logo - style you can find in header.scss -->
  <!-- ============================================================== -->

  <!-- ============================================================== -->
  <!-- sidebar toggle -->
  <!-- ============================================================== -->

  <!-- ============================================================== -->
  <!-- Search - style you can find in header.scss -->
  <!-- ============================================================== -->

  <!-- ============================================================== -->
  <!-- app header component - style you can find in header.scss / header.component.ts-->
  <!-- ============================================================== -->
  <!-- loading shade -->

  <!-- loading shade -->
  <!-- ============================================================== -->
  <!-- Right sidebar toggle - style you can find in rightsidebar.component.scss -->
  <!-- ============================================================== -->

  <div class="top-bar-custom"></div>
  <!-- ============================================================== -->
  <!-- End Topbar - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
    [ngClass]="{'minisidebar': minisidebar}">

    <!-- *ngIf="haveSidebar == 1" -->
    <mat-sidenav *ngIf="sidebarToSee == 1" #navbarOpen id="navbarOpen" class="pl-xs"
      [mode]="mobileQuery.matches ? 'side' : 'over'" fixedTopGap="0"
      [opened]="mobileQuery.matches || sidebarOpened == true" [disableClose]="mobileQuery.matches"
      (open)="sidebarOpened == true" (close)="sidebarOpened == false">

      <perfect-scrollbar *ngIf="!horizontal; else horizontalsidebar" class="scbar" [config]="config">

        <app-vertical-sidebar-open class="app-sidebar" (onSelectedMenuItem)="setToggle($event);clickEvent()">

        </app-vertical-sidebar-open>
      </perfect-scrollbar>

      <ng-template #horizontalsidebar>

        <app-horizontal-sidebar-open>

        </app-horizontal-sidebar-open>

      </ng-template>


    </mat-sidenav>
    <mat-sidenav-content class="content-for-open" [ngClass]="removeForSidebar() ? 'no-limit' : 'content-for-open'"
      class="page-wrapper" [ngClass]="status ? 'minitoggle' : ''">

      <mat-toolbar class="custom-toolbar">
        <div class=" custom-navbar-container">


          <div *ngIf="sidebarToSee == 1" (click)="clickEvent();setOpenSidebar()" class="sidebar-toggle"
            value="sidebarclosed">
            <mat-icon class="menu-icon-custom">menu</mat-icon>
            <img class="open-navbar-custom" src="assets/images/icon/menu-sidebar.svg" alt="">
          </div>




          <app-vertical-header-open class="navbar-custom" [callbackFunction]="myCallbackFunction"
            [titleKey]="titlePageKey"></app-vertical-header-open>


        </div>

      </mat-toolbar>
      <app-breadcrumb-open>

      </app-breadcrumb-open>

      <div class="page-content">
        <router-outlet (activate)="onChildLoaded($event,$event)">
          <app-spinner></app-spinner>
        </router-outlet>

      </div>
    </mat-sidenav-content>


  </mat-sidenav-container>
</div>