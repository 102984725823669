

import { MediaMatcher } from '@angular/cdk/layout';
import { Router, NavigationEnd, ActivatedRoute, Data } from '@angular/router';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit


} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

import { MenuItems } from '../../shared/menu-items/menu-items';
import { filter, map, mergeMap } from 'rxjs/operators';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Title, BrowserModule } from '@angular/platform-browser';

import { OpenComponentLayoutServices } from './vertical-sidebar-open/vertical-sidebar-open.service';

import { MatDialog } from '@angular/material/dialog';


import * as mixpanel from 'mixpanel-browser';

import { EFindServicesOpenComponent } from 'src/app/aopen/search_services/e-find-services-open.component';

import { VerticalAppSidebarOpenComponent } from './vertical-sidebar-open/vertical-sidebar-open.component';

import { ProfileAvailableOpenComponent } from 'src/app/aopen/profile-available/profile-available-open.component';
import { NeedOpenCreateComponent } from 'src/app/aopen/need-open-create/need-open-create.component';
import { CompleteDetailOpenComponent } from 'src/app/aopen/complete-detail/complete-detail-open.component';

import { AuthenticationService } from 'src/app/authentication/authentication.service';

import { NgxPendoService } from 'ngx-pendo';
import { OpenService } from 'src/app/aopen/open.service';
import { AppComponent } from 'src/app/app.component';


/** @title Responsive sidenav */
@Component({
  selector: 'open-layout',
  templateUrl: 'open.component.html',
  styleUrls: ['./open.component.scss']
})



export class OpenComponent implements OnDestroy, OnInit {

  pageInfo: Data = Object.create(null);
  titlePage: any;
  titlePageKey: any;
  mobileQuery: MediaQueryList;
  dir = 'ltr';
  green = false;
  blue = false;
  dark = false;
  minisidebar = false;
  boxed = false;
  danger = false;
  showHide = false;
  horizontal = false;
  url = '';
  sidebarOpened = false;
  status = false;
  redirect = "";

  public showSearch = false;
  assumedUser: any;
  versionProduct: any;
  token: any;
  countModaleExpired = 0;
  limitations: any = [];
  limitationsArray: any = [];
  manageUserBlock = { blocked: false, action: 1 };
  discoverCompatibleProfileBlock = { blocked: false, action: 1, create: false, sourcePage: '' };
  timeMaterialBlock = { blocked: false, action: 1, create: false, sourcePage: '' };
  fixedPriceBlock = { blocked: false, action: 1, create: false, sourcePage: '' };
  showEstimateBlock = { blocked: false, action: 1, create: false, sourcePage: '' };
  hireBlock = { blocked: false, action: 1 };
  chatBlock = { blocked: false, action: 1, sourcePage: '' };
  globalState = { idPlanCurrent: 0, isOldPlan: false, countPosition: 0, max_position: 0, transactionFee: null };
  requestAvailableBlock = { blocked: false, action: 1 };
  emailsContactBlock = { blocked: false, action: 1 };
  shareProfileBlock = { blocked: false, action: 1 };
  shareProjectBlock = { blocked: false, action: 1 };
  informationBlock = { blocked: false, action: 1 };
  downloadCVBlock = { blocked: false, action: 1 };
  downloadCompanyReportBlock = { blocked: false, action: 1 };
  searchPartnerBlock = { blocked: false, action: 1 };
  activeSegmentDev = false;
  counterCallRefreshToken = 0;
  state: any;
  existInLimitationSearch = false;
  isLoadingResults: any = false
  public config: PerfectScrollbarConfigInterface = {};
  private _mobileQueryListener: () => void;
  newMessagesLed: any;
  codificArrayR: any = [];
  codificArrayE: any = [];
  codificKnow: any = [];
  codificArrayS: any = [];
  haveSidebar: any;
  type_version: any;
  type_caller: any;
  sidebarToSee: any = 1;
  languageUser: any = 'it';
  pageWidth: any = screen.width;

  constructor(
    public _authService: AuthenticationService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private translate: TranslateService,
    private _service: OpenComponentLayoutServices,
    public dialog: MatDialog,
    protected ngxPendoService: NgxPendoService,
    private _openService: OpenService,
    public appComponent: AppComponent,
    // public component_1 : VerticalAppHeaderOpenComponent

  ) {
    this.mobileQuery = media.matchMedia('(min-width: 1024px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    // this.getCodificValues()
    // this.haveSidebar = sessionStorage.getItem('haveSidebar')
    // console.log(this.haveSidebar)
    this.setTitler('it');

  }


  ngOnInit() {

    let route = window.location.href
    if (route.includes('redirect_token')) {
      const myArray = route.split("/");
      console.log(myArray);
      const n = 3;
      const result = myArray.slice(-n);
      console.log(result);
      let lastRes = result[2].split("?")
      let LastRes: any = lastRes.slice(0, 1)
      let sidebar: any;
      let type: any;
      let utm_campaign: any;
      let utm_medium: any;
      let utm_source: any;
      let inside: any;
      let language: any;
      let company_id: any;
      let employee_id: any;
      let mode_operation: any;

      this.activatedRoute.queryParams.subscribe(params => {
        sidebar = params.sidebar;
        type = params.type;
        utm_campaign = params.utm_campaign;
        utm_medium = params.utm_medium;
        utm_source = params.utm_source;
        inside = params.inside
        language = params.language
        this.languageUser = params.language;
        company_id = params.company_id;
        employee_id = params.employee_id;
        mode_operation = params.mode_operation;
      })


      if (sidebar == 0 || sidebar == 1 || sidebar == '1' || sidebar == '0') {
        this.sidebarToSee = sidebar;
      }
      this.pageWidth = screen.width;
      // if (this.pageWidth < 800) {
      //   this.sidebarToSee = 0
      // }
      var redirect_url = result[0]
      this.type_version = parseInt(result[1])
      this.type_caller = parseInt(LastRes)
      let firstAccess = sessionStorage.getItem('firstAccessOpenToken')

      firstAccess = 'false'

      if (language == null) {
        language = 'it';
      }

      if (sidebar == 0 && (!firstAccess || firstAccess == null || firstAccess == undefined || firstAccess == 'false') && (!window.location.hostname.toLowerCase().includes('esprinet') && window.location.href.toLowerCase().includes('open'))) {
        this.isLoadingResults = true
        this._service.addToken(redirect_url, this.type_version, this.type_caller, utm_source, utm_medium, utm_campaign, sidebar, language, company_id, employee_id, mode_operation).then(res => {
          let url
          if (redirect_url != 'need_open_create') {
            url = res.data.origin + '/open/' + res.data.urlRedirect + '/' + res.data.token + '/' + res.data.versionProduct + '/' + res.data.typeCaller;
          } else {
            url = res.data.origin + '/open/' + res.data.urlRedirect + '/' + res.data.token + '/' + res.data.versionProduct + '/' + res.data.typeCaller + '?type=' + type
          }


          if (window.location.host == "marketplace.timeflow.it") {
            this.initializePendo(res)
          } else if (window.location.host == "penetration-test.timeflow.it") {
            console.log('noPendo');
          } else if (window.location.host == "daytrend.it") {
            console.log('noPendo');
          } else if (window.location.host == "procurement.timeflow.it") {
            console.log('noPendo');
          } else if (window.location.host == "whitelabel.daytrend.it") {
            console.log('noPendo');
          } else if (window.location.host == "procurement.daytrend.it") {
            console.log('noPendo');
          } else if (window.location.host == "dev.mkt.daytrend.it") {
            console.log('noPendo');
          }



          this.token = res.data.token;
          this.isLoadingResults = false
          sessionStorage.setItem('tt', res.data.token)
          sessionStorage.setItem('versionProduct', res.data.versionProduct);
          sessionStorage.setItem('firstAccessOpenToken', 'true')
          if (redirect_url == 'complete_detail') {
            this.router.navigate(['/open/' + res.data.urlRedirect + '/' + res.data.token + '/' + company_id + '/null/' + employee_id + '/null/null/null/' + mode_operation])
          } else if (redirect_url == 'need_open_create') {
            this.router.navigate(['/open/' + res.data.urlRedirect + '/' + res.data.token], { queryParams: { type: type } })
          } else {
            this.router.navigate(['/open/' + res.data.urlRedirect + '/' + res.data.token])
          }

          console.log(url);

          if (res.data.language) {
            sessionStorage.setItem('language', res.data.language)
            // this.component_1.changeInternalLang(res.data.language);
          } else {
            sessionStorage.setItem('language', 'it')

          }




        }).catch(error => {

          console.log(error);



        });
      }
    }

    if (window.location.hostname.toLowerCase().includes('esprinet')) {
      this.sidebarToSee = '0'
    }
  }

  ngAfterViewInit() {
    this.token = sessionStorage.getItem('tt') ?? this._openService.currentToken;
  }


  initializePendo(res: any) {
    this.ngxPendoService.initialize({
      id: res.data.token,
    }, {
      id: res.data.token
    });

  }

  setToggle(val: boolean) {
    this.status = val;
  }

  // getCodificValues() {
  //   var token = this.activatedRoute.snapshot.paramMap.get('token');
  //   this.token = token
  //   this._authService.getCodificValues(this.languageUser).then((res: any) => {
  //     //console.log(res.data);
  //     this.codificArrayE = res.data.number_employees
  //     this.codificArrayR = res.data.revenue
  //     this.codificArrayS = res.data.seniority
  //     this.codificKnow = res.data.how_do_you_know

  //   })
  // }


  removeForSidebar() {
    let pageWidth = screen.width;
    if (pageWidth <= 1022) {
      return true
    } else {
      return false
    }
  }

  clickEvent() {
    this.status = !this.status;
  }

  setOpenSidebar() {
    console.log(this.sidebarOpened);

    this.sidebarOpened = !this.sidebarOpened
  }


  getCodificArray(type: any) {
    switch (type) {
      case 'E':
        return this.codificArrayE
        break;
      case 'R':
        return this.codificArrayR
        break;
      case 'S':
        return this.codificArrayS
        break;

      default:
        break;
    }
  }


  ngOnDestroy(): void {
    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this._mobileQueryListener);

  }

  ngDoCheck(): void {
    if (sessionStorage.getItem('verifyBtn') == 'true') {
      sessionStorage.removeItem('verifyBtn');
    }
  }

  myCallbackFunction = (element: any, lang: any): void => {
    // console.log('sono qui')

    var title = element;


    if (lang == 'it') {

      for (let [key, value] of Object.entries(this.translate.translations.it)) {
        if (title == key) {
          this.titlePage = value;
          //console.log(this.titlePage);

          this.titleService.setTitle(this.titlePage);
          this.titlePageKey = key;
        }

        if (title == 'My Profile' || title == 'aSecond Level' || title == 'Dashboards') {
          this.titlePage = '';
          this.titlePageKey = key;
        }
      }

    } else if (lang == 'en') {


      for (let [key, value] of Object.entries(this.translate.translations.en)) {
        if (title == key) {
          this.titlePage = value;
          this.titleService.setTitle(this.titlePage);
          this.titlePageKey = key
        }

        if (title == 'My Profile' || title == 'aSecond Level' || title == 'Dashboards') {
          this.titlePage = '';
          this.titlePageKey = key;
        }
      }

    }
  }



  setTitler(lang: any) {

    // console.log('sono qui')
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter(route => route.outlet === 'primary'))
      .pipe(mergeMap(route => route.data))
      .subscribe(event => {
        setTimeout(() => {
          this.titleService.setTitle(event['title']);
          var title = event['title'];
          //console.log(event)

          // sessionStorage.removeItem('backRequest');
          if (title == 'Dashboard' || title == 'Dashboards') {
            this.titleService.setTitle(title);
          } else {
            if (lang == 'it') {


              for (let [key, value] of Object.entries(this.translate.translations.it)) {
                if (title == key) {
                  this.titlePage = value;
                  //console.log(this.titlePage);

                  this.titleService.setTitle(this.titlePage);
                  this.titlePageKey = key;




                }
                if (title == 'My Profile' || title == 'aSecond Level' || title == 'Dashboards') {
                  this.titlePage = '';
                  this.titlePageKey = key;
                }
              }


            } else if (lang == 'en') {


              for (let [key, value] of Object.entries(this.translate.translations.en)) {
                if (title == key) {
                  this.titlePage = value;
                  this.titleService.setTitle(this.titlePage);
                  this.titlePageKey = key;



                }
                if (title == 'My Profile' || title == 'aSecond Level' || title == 'Dashboards') {
                  this.titlePage = '';
                  this.titlePageKey = key;
                }
              }

            }
          }


          let filtersCloud = JSON.parse(sessionStorage.getItem("filtersBackup")!);
          if (filtersCloud) {
            sessionStorage.removeItem('filtersBackup')
          }

        }, 800);

      });

  }
  refreshToken(viewModal: any) {


  }

  trackSegment(obj: any) {

    var date = new Date();
    var dateStr =
      ("00" + date.getDate()).slice(-2) + "/" +
      ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
      date.getFullYear() + " " +
      ("00" + date.getHours()).slice(-2) + ":" +
      ("00" + date.getMinutes()).slice(-2) + ":" +
      ("00" + date.getSeconds()).slice(-2);




    var heightPage = window.screen.height;
    var widthPage = window.screen.width;

    mixpanel.init('71b2c8ca9dcb37477315518497a82c4a', { debug: false });

    this.versionProduct = JSON.parse(sessionStorage.getItem("versionProduct")!);
    var token = this.activatedRoute.snapshot.paramMap.get('token');
    this.token = token

    this.versionProduct = JSON.parse(sessionStorage.getItem("versionProduct")!);

    if (this.versionProduct == 2) {

      if (obj.action.includes('request_availability')) {

        mixpanel.track(obj.action, {
          date: dateStr,
          width_page: widthPage,
          heigth_page: heightPage,
          token: token,
          page_name: obj.page_name,
          dettaglio_profilo: obj.dettaglio_profilo == 1 ? 'Y' : 'N',
          id_profilo: obj.id_profilo
        });

      } else if (obj.action.includes('request_availability_email')) {

        mixpanel.track(obj.action, {
          date: dateStr,
          width_page: widthPage,
          heigth_page: heightPage,
          token: token,
          page_name: obj.page_name,
          dettaglio_profilo: obj.dettaglio_profilo == 1 ? 'Y' : 'N',
          id_profilo: obj.id_profilo
        });

      } else if (obj.action.includes('sign_up')) {

        mixpanel.track(obj.action, {
          date: dateStr,
          width_page: widthPage,
          heigth_page: heightPage,
          token: token,
          page_name: obj.page_name,
          dettaglio_profilo: obj.dettaglio_profilo == 1 ? 'Y' : 'N',
          source: obj.source
        });

      } else if (obj.action.includes('publish_FP')) {

        mixpanel.track(obj.action, {
          date: dateStr,
          width_page: widthPage,
          heigth_page: heightPage,
          token: token,
          page_name: obj.page_name,
          step: obj.step
        });

      } else if (obj.action.includes('publish_T&M')) {

        mixpanel.track(obj.action, {
          date: dateStr,
          width_page: widthPage,
          heigth_page: heightPage,
          token: token,
          page_name: obj.page_name,
          step: obj.step
        });

      }

    }

  }




  onChildLoaded(
    component:
      EFindServicesOpenComponent |
      VerticalAppSidebarOpenComponent |
      ProfileAvailableOpenComponent |
      NeedOpenCreateComponent |
      CompleteDetailOpenComponent
    ,
    component_codific:
      EFindServicesOpenComponent |
      NeedOpenCreateComponent
  ) {
    setTimeout(() => {
      // component.sharedTitle = this.titlePage;
      // console.log(this.titlePage);

      component_codific.codificArrayE = this.codificArrayE
      component_codific.codificArrayR = this.codificArrayR
      component_codific.codificArrayS = this.codificArrayS
      component_codific.how_do_you_know = this.codificKnow;

      let logo;
      logo = this.appComponent.logoSpinner
      component.logo = logo
      // if (this.versionProduct == 2) {
      //   logo = 'assets/images/new-card-icons/loading-logo.svg';
      // } else if (this.versionProduct == 1) {
      //   if (window.location.host == "procurement.timeflow.it") {
      //     logo = 'assets/images/new-card-icons/loading-logo.svg';
      //   } else if (window.location.host == "whitelabel.daytrend.it") {
      //     logo = 'assets/images/new-card-icons/loading-logo.svg';
      //   } else if (window.location.host == "procurement.daytrend.it") {
      //     logo = 'assets/images/new-card-icons/loading-logo.svg';
      //   } else if (window.location.host == "dev.mkt.daytrend.it") {
      //     logo = 'assets/images/new-card-icons/loading-logo.svg';
      //   } else if (window.location.hostname.toLowerCase().includes('esprinet')) {
      //     logo = 'assets/images/new-card-icons/esprinet-logo-spin.svg';
      //   } else {
      //     logo = 'assets/images/new-card-icons/loading-logo-proc.svg';
      //   }
      // }

    }, 900);

  }
  // Mini sidebar
}
