
<div class="custom-modal">
  <div class="first-content" *ngIf="this.local_data.type == 1">
        <div class="title">
          {{"ShareModalCopy.TitleProf" | translate}}
        </div>
        <div class="content">
          <div class="field-custom">
            <mat-form-field>
              <input type="text" maxlength="50" matInput required id="mailto" name="mailto"
                [(ngModel)]="mailObj.to" placeholder="Mail to" />
            </mat-form-field>
          </div>
          <div class="field-custom">
         <div class="field-custom">
           <mat-form-field>
             <input type="text" maxlength="500" matInput id="mailcc1" name="mailcc" placeholder="Email CC"
               [(ngModel)]="ccMailVar" (keydown.enter)="addCC($event)" />
           </mat-form-field>
           <small *ngIf="ccMailVar && ccMailVar.length >= 1"> {{"ShareModalCopy.Disclamer" | translate}}</small>
           <mat-chip-list #chipList style="margin-top: 12px;">
             <mat-chip *ngFor="let cc of mailObj.cc" [selectable]="selectable" >
               {{cc}}
               <mat-icon matChipRemove (click)="remove(cc)">cancel</mat-icon>
             </mat-chip>
           </mat-chip-list>
         </div>
          </div>


            <div class="description-field-custom">
              <mat-form-field>
               <textarea class="text-area-custom" [placeholder]="('ShareModalCopy.PlaceholderArea' | translate)" required
                 [(ngModel)]="mailObj.content" name="description" id="description"
                 [formControl]="firstFormGroup.controls['content']" matInput cdkTextareaAutosize
                 #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" maxlength="499" cdkAutosizeMaxRows="5"></textarea>
              </mat-form-field>
            </div>

            <div class="checkbox-filter">
              <label for="checkbox-one">{{"ShareModalCopy.SendMe" | translate}}</label>

              <input #checkboxone type="checkbox" id="checkbox-one" name="checkbox-one"
                class="checkbox-one" (click)="sendToMe()">
              <img
                [src]="checkboxValue1.checked == true ?'assets/images/new-card-icons/checkbox-checked.svg': 'assets/images/new-card-icons/checkbox-unchecked.svg'"
                alt="">
            </div>

            <div class="resume-block" *ngIf="noData == false">
              <div class="title-resume">
                {{"ShareModalCopy.ProfSend" | translate}} {{noData == false ? this.preload.length : ''}} {{this.preload.length == 1 ?('ShareModalCopy.Onetime' | translate) : ('ShareModalCopy.Multiple' | translate)}}
              </div>
              <div class="sending-info" *ngFor="let item of this.preload">
                <div class="date">{{item.date_send }}</div>
                <div class="spot"></div>
                <div class="specs">
                  <div>To: {{item.email_to}}</div>
                  <div *ngIf="item.emails_cc && item.emails_cc.length != 0 && item.emails_cc[0] != ''">CC: <span
                      *ngFor="let mail of item.emails_cc">{{mail}}</span></div>
                </div>
              </div>
            </div>

          <div class="buttons">
             <div class="blue-default button-medium" (click)="closeDialog()">
               {{"ShareModalCopy.Cancel" | translate}}
             </div>
             <div class="blue-default button-medium"
               (click)="!firstFormGroup.valid || loading==true ? $event.stopPropagation() : finish()"
              [ngClass]="!firstFormGroup.valid || loading==true ? 'inactive-default':'blue-default'">
               {{"ShareModalCopy.Share" | translate}}
             </div>
          </div>
        </div>
  </div>
  <div class="second-content" *ngIf="this.local_data.type == 2">

 <div class="title">
    {{"ShareModalCopy.TitleProj" | translate}}
 </div>
 <div class="content">
   <div class="field-custom">
     <mat-form-field>
       <input type="text" maxlength="50" matInput required id="mailto" name="mailto" [(ngModel)]="mailObj.to"
         placeholder="Mail to" />
     </mat-form-field>
   </div>
   <div class="field-custom">
     <mat-form-field>
       <input type="text" maxlength="500" matInput id="mailcc2" name="mailcc"
         placeholder="Email CC" [(ngModel)]="ccMailVar" (keydown.enter)="addCC($event)" />
        </mat-form-field>
      <small *ngIf="ccMailVar && ccMailVar.length >= 1">{{"ShareModalCopy.Disclamer" | translate}}</small>
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let cc of mailObj.cc" [selectable]="selectable">
          {{cc}}
          <mat-icon matChipRemove (click)="remove(cc)">cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
   </div>


   <div class="description-field-custom">
     <mat-form-field>
       <textarea class="text-area-custom" [placeholder]="('ShareModalCopy.PlaceholderArea' | translate)" required [(ngModel)]="mailObj.content"
         name="description" id="description" [formControl]="firstFormGroup.controls['content']" matInput
         cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" maxlength="499"
         cdkAutosizeMaxRows="5"></textarea>
     </mat-form-field>
   </div>

   <div class="checkbox-filter">
     <label for="checkbox-one">{{"ShareModalCopy.SendMe" | translate}}</label>

     <input #checkboxone type="checkbox" id="checkbox-one" name="checkbox-one" class="checkbox-one"
       (click)="sendToMe()">
     <img
       [src]="checkboxValue1.checked == true ?'assets/images/new-card-icons/checkbox-checked.svg': 'assets/images/new-card-icons/checkbox-unchecked.svg'"
       alt="">
   </div>

    <div class="resume-block" *ngIf="noData == false">
      <div class="title-resume">
        {{"ShareModalCopy.ProjSend" | translate}} {{noData == false ? this.preload.length : ''}} {{this.preload.length == 1 ?('ShareModalCopy.Onetime' | translate) : ('ShareModalCopy.Multiple' | translate)}}
      </div>
      <div class="sending-info" *ngFor="let item of this.preload">
        <div class="date">{{item.date_send }}</div>
        <div class="spot"></div>
        <div class="specs">
          <div>To: {{item.email_to}}</div>
          <div *ngIf="item.emails_cc && item.emails_cc.length != 0 && item.emails_cc[0] != ''">CC: <span
              *ngFor="let mail of item.emails_cc">{{mail}}</span>
          </div>
        </div>
      </div>
    </div>

   <div class="buttons">
     <div class="blue-default button-medium" (click)="closeDialog()">
        {{"ShareModalCopy.Cancel" | translate}}
     </div>
     <div class="blue-default button-medium"
       (click)="!firstFormGroup.valid || loading==true ? $event.stopPropagation() : finish()"
       [ngClass]="!firstFormGroup.valid || loading==true ? 'inactive-default':'blue-default'">
       {{"ShareModalCopy.Share" | translate}}
     </div>
   </div>
 </div>

  </div>
</div>
