import { Component, ElementRef, EventEmitter, Inject, Injectable, OnInit, Optional, Output, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Helper } from 'src/helper/helper';
import { FullComponent } from '../layouts/full/full.component';
import { ShareModalCvProjectService } from './share-modal-cv-project.service';
@Pipe({ name: "dateTimeFormatFilter" })
@Injectable()
export class DateTimeFormatPipe implements PipeTransform {
  transform(date: any, format: string): any {
    if (date) {
      return moment(date).format(format);
    }
  }
}
@Component({
  selector: 'app-share-modal-cv-project',
  templateUrl: './share-modal-cv-project.component.html',
  styleUrls: ['./share-modal-cv-project.component.scss']
})
export class ShareModalCvProjectComponent implements OnInit {
  @Output()
  onClose: EventEmitter<boolean> = new EventEmitter();
  local_data: any;
  mailObj: any = { to: '', content: '', cc: [], sendMe: false }
  checkboxValue1: any;
  ccMailVar = ''
  token: any;
  preload: any = [{}];
  noData: any = false;
  specs: any
  numberSending: any = 0;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end'; // start, center, end, left, right
  verticalPosition: MatSnackBarVerticalPosition = 'top'; // top, bottom
  currentUser: any;
  firstFormGroup: FormGroup = Object.create(null);
  @ViewChild('checkboxone') checkboxone: ElementRef<HTMLElement> = Object.create(null);
  constructor(private _snackBar: MatSnackBar, private translate: TranslateService, private _helper: Helper, public fullComponent: FullComponent, private service: ShareModalCvProjectService, public dialogRef: MatDialogRef<ShareModalCvProjectComponent>, private _formBuilder: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.local_data = { ...data };
    //console.log(this.local_data);
    this.checkboxValue1 = this.checkboxone;
    this.firstFormGroup = this._formBuilder.group({
      content: [null, [Validators.required, Validators.maxLength(498)]],
    });
    dialogRef.disableClose = true;
    this.token = JSON.parse(sessionStorage.getItem("loginUserToken")!);
    this.currentUser = JSON.parse(sessionStorage.getItem("loginUser")!);
    setTimeout(() => {
      let modal = document.getElementsByClassName('custom-modal')[0];
      let modalBase = document.getElementsByClassName('mat-dialog-container')[0];
      modal.setAttribute('style', 'opacity:1');
      modalBase.setAttribute('style', 'display:block');
    }, 1);
    setTimeout(() => {
      this.specs = <HTMLElement>document.getElementsByClassName('resume-block')[0];
    }, 1);
  }

  ngOnInit(): void {
    setTimeout(() => {
      let modal = document.getElementsByClassName('custom-modal')[0];
      let modalBase = document.getElementsByClassName('mat-dialog-container')[0];
      modal.setAttribute('style', 'opacity:1');
      modalBase.setAttribute('style', 'display:block');
    }, 1);
    this.loadData()
  }
  addCC(event: any) {
    //console.log(this.ccMailVar);
    if (!this.mailObj.cc.includes(this.ccMailVar)) {
      this.mailObj.cc.push(this.ccMailVar)
      //console.log(this.mailObj);
      this.ccMailVar = ''
    } else {
      this.ccMailVar = ''
    }

  }

  loadData() {
    setTimeout(() => {
      this.specs = <HTMLElement>document.getElementsByClassName('resume-block')[0];
    }, 1);
    //console.log('obj');
    let obj = {
      "type": this.local_data.type,
      "id_profile": this.local_data.id_custom,
      "id_client_need": this.local_data.id_client_need_custom,
      "id_need_resource": this.local_data.id_need_resource_custom,
      "id_project_fixed_price": this.local_data.id_project_fp_custom,
    }
    this.service.getSendInfo(obj).then(res => {
      this.preload = res.data;
      if (this.preload == null || this.preload == undefined || this.preload[0].email_to == undefined || this.preload[0].email_to == null || (this.preload[0].email_to && this.preload[0].email_to.length == 0)) {
        this.noData = true;
      }
      if (this.noData == false) {
        setTimeout(() => {
          this.specs = <HTMLElement>document.getElementsByClassName('resume-block')[0];
          this.specs.setAttribute('style', 'display:flex');
        }, 1);
      }
    }).catch(error => {
      if (error.status == 401) {
        this.fullComponent.refreshToken(1);

      } else {
        this.manageError();
      }

    });
  }
  sendToMe() {
    this.mailObj.sendMe = !this.mailObj.sendMe
    this.checkboxValue1.checked = !this.checkboxValue1.checked
  }
  finish() {
    let obj = {
      "emails": { to: this.mailObj.to, cc: this.mailObj.cc },
      "type": this.local_data.type,
      "id_profile": this.local_data.id_custom,
      "id_client_need": this.local_data.id_client_need_custom,
      "id_need_resource": this.local_data.id_need_resource_custom,
      "id_project_fixed_price": this.local_data.id_project_fp_custom !== undefined ? this.local_data.id_project_fp_custom : null,
      "note": this.mailObj.content,
      "sendCopy": this.mailObj.sendMe == true ? 1 : this.mailObj.sendMe == false ? 0 : 0
    }

    this.service.addSendInfo(obj).then(res => {
      if (res.success) {
        this.dialogRef.close({ event: 'Done' });
        this._snackBar.open(res.message, '', {
          duration: 5000,
          panelClass: ['success-snackbar'],
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,

        });
      }

    }).catch(error => {
      if (error.status == 401) {
        this.fullComponent.refreshToken(1);

      } else {
        this.manageError();
      }

    });

  }
  remove(cc: string): void {
    const index = this.mailObj.cc.indexOf(cc);

    if (index >= 0) {
      this.mailObj.cc.splice(index, 1);
    }
  }
  closeDialog() {
    let modalBase = document.getElementsByClassName('mat-dialog-container')[0];
    modalBase.setAttribute('style', 'display:none');
    this.dialogRef.close({ event: 'Cancel' });
  }

  manageError() {
    if (this.currentUser.language == "it") {
      var errorMessage = this.translate.translations.it.ErrorServer;
    } else {
      var errorMessage = this.translate.translations.en.ErrorServer;
    }


    this._snackBar.open(errorMessage, '', {
      duration: 5000,
      panelClass: ['danger-snackbar'],
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,

    });
  }
}
