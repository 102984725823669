
<div class="topbar-blocks-container">
 <div id="playbar"></div>

 <div class="block language-block-top">
    <div class="notification-custom custom-flag" [matMenuTriggerFor]="flags" >
      <span>{{selectedLanguage.view}}</span>
      <img class="chevron-down-custom" src="assets/images/topbr-icons/chevron-down-icon.svg" alt=""
        alt="chevron-down">
    </div>
    <mat-menu #flags="matMenu" class="language-custom-menu">

      <button mat-menu-item *ngFor="let lang of languages" (click)="changeLanguage(lang)">
        <span>{{lang.icon}}</span>
      </button>

    </mat-menu>
  </div>



  <div class="block upgrade-block-top">
    <div class="upgrade-button"
      (click)="goToOtherPage('register')">
      Sign In
    </div>
  </div>


</div>
