import { Injectable } from '@angular/core';
export interface BadgeItem {
    type: string;
    value: string;
}
export interface Saperator {
    name: string;
    type?: string;
}
export interface ChildrenItems {
    state: string;
    name: string;
    type?: string;
}

export interface Menu {
    state: string;
    name: string;
    type: string;
    icon: string;
    badge?: BadgeItem[];
    saperator?: Saperator[];
    children?: ChildrenItems[];
}

var versionProduct = JSON.parse(sessionStorage.getItem("versionProduct")!);
var currentUser = JSON.parse(sessionStorage.getItem("loginUser")!);

if(!versionProduct){
    versionProduct = 2;
}

var ADMINMENUITEMS : any = [];
if(versionProduct == 2){
    ADMINMENUITEMS = [
        {
            state: 'company',
            name: 'Company',
            type: 'link',
            icon: 'company-custom'
        },
        {
            state: 'profile_available',
            name: 'Profile Available',
            type: 'link',
            icon: 'available-profiles-custom'
        },
        {
            state: 'position_list',
            name: 'Position List',
            type: 'link',
            icon: 'open-position-custom'
        },
        {
            state: 'accademy_profiles',
            name: 'Profile Accademy',
            type: 'link',
            icon: 'academy-custom'
        },

        {
            state: 'technology',
            name: 'Technology',
            type: 'link',
            icon: 'tech-custom'
        },
        {
            state: 'job_sector',
            name: 'Job Sector',
            type: 'link',
            icon: 'cake-chart-custom'
        },
        {
            state: 'description_pages',
            name: 'Description Pages',
            type: 'link',
            icon: 'descriptions-custom'
        },
        {
            state: 'employee_review',
            name: 'Employee Review',
            type: 'link',
            icon: 'profile-review-custom'
        },
        {
          state: 'pending_inscriptions',
          name: 'Pending Inscriptions',
          type: 'link',
          icon: 'company-custom'
        },
        {
          state: 'certifications',
          name: 'Certifications',
          type: 'link',
          icon: 'descriptions-custom'
        },
        {
          state: 'product_category',
          name: 'Product Category',
          type: 'link',
          icon: 'cake-chart-custom'
        },
        {
          state: 'languages',
          name: 'Languages',
          type: 'link',
            icon: 'languages-custom'
        },
        {

            state: 'company_services',
            name: 'Company Services',
            type: 'link',
            icon: 'tech-custom'
        },

    ];
} else {
    ADMINMENUITEMS = [
        {
            state: 'company',
            name: 'Company',
            type: 'link',
            icon: 'company-custom'
        },
        {
            state: 'profile_available',
            name: 'Profile Available',
            type: 'link',
            icon: 'available-profiles-custom'
        },
        {
            state: 'position_list',
            name: 'Position List',
            type: 'link',
            icon: 'open-position-custom'
        },
        {
            state: 'technology',
            name: 'Technology',
            type: 'link',
            icon: 'tech-custom'
        },
        {
            state: 'job_sector',
            name: 'Job Sector',
            type: 'link',
            icon: 'cake-chart-custom'
        },
        {
            state: 'description_pages',
            name: 'Description Pages',
            type: 'link',
            icon: 'descriptions-custom'
        },
        {
          state: 'certifications',
          name: 'Certifications',
          type: 'link',
          icon: 'descriptions-custom'
        },
        {
          state: 'product_category',
          name: 'Product Category',
          type: 'link',
          icon: 'cake-chart-custom'
        },
        {
          state: 'languages',
          name: 'Languages',
          type: 'link',
            icon: 'languages-custom'
        },
        {

            state: 'company_services',
            name: 'Company Services',
            type: 'link',
            icon: 'tech-custom'
        },

    ];
}

var MENUITEMS : any = [];
if(versionProduct == 2){
    MENUITEMS = [

        {
            state: 'company',
            name: 'Company',
            type: 'link',
            icon: 'company-custom'
        },
        {
            state: 'profile_available',
            name: 'Profile Available',
            type: 'link',
            icon: 'available-profiles-custom'
        },
        {
            state: 'position_list',
            name: 'Position List',
            type: 'link',
            icon: 'open-position-custom'
        },
        {
            state: 'accademy_profiles',
            name: 'Profile Accademy',
            type: 'link',
            icon: 'academy-custom'
        },
        {
            state: 'collaborations_admin',
            name: 'Collaborations Admin',
            type: 'link',
            icon: 'collaborations-custom'
        },
        {
            state: 'plans',
            name: 'Plans',
            type: 'link',
            icon: 'plan-custom'
        },
        {
            state: 'technology',
            name: 'Technology',
            type: 'link',
            icon: 'tech-custom'
        },
        {
            state: 'job_sector',
            name: 'Job Sector',
            type: 'link',
            icon: 'cake-chart-custom'
        },

        {
            state: 'description_pages',
            name: 'Description Pages',
            type: 'link',
            icon: 'descriptions-custom'
        },
        {
            state: 'employee_review',
            name: 'Employee Review',
            type: 'link',
            icon: 'profile-review-custom'
        },
        {
            state: 'email_notifications',
            name: 'Email Notifications',
            type: 'link',
            icon: 'bell-custom'
        },

        {
          state: 'pending_inscriptions',
          name: 'Pending Inscriptions',
          type: 'link',
          icon: 'company-custom'
        },
        {
          state: 'certifications',
          name: 'Certifications',
          type: 'link',
          icon: 'descriptions-custom'
        },
        {
          state: 'product_category',
          name: 'Product Category',
          type: 'link',
          icon: 'cake-chart-custom'
        },
        {

          state: 'company_services',
          name: 'Company Services',
          type: 'link',
          icon: 'tech-custom'
          },
        {
          state: 'manage_admin_users',
          name: 'Manage Users Admin',
          type: 'link',
          icon: 'manage-user-custom'
        },
        {
          state: 'languages',
          name: 'Languages',
          type: 'link',
            icon: 'languages-custom'
        }
    ];
} else  {
    MENUITEMS = [

        {
            state: 'company',
            name: 'Company',
            type: 'link',
            icon: 'company-custom'
        },
        {
            state: 'profile_available',
            name: 'Profile Available',
            type: 'link',
            icon: 'available-profiles-custom'
        },
        {
            state: 'position_list',
            name: 'Position List',
            type: 'link',
            icon: 'open-position-custom'
        },
        {
            state: 'technology',
            name: 'Technology',
            type: 'link',
            icon: 'tech-custom'
        },
        {
            state: 'job_sector',
            name: 'Job Sector',
            type: 'link',
            icon: 'cake-chart-custom'
        },
        {
            state: 'description_pages',
            name: 'Description Pages',
            type: 'link',
            icon: 'descriptions-custom'
        },
        {
            state: 'email_notifications',
            name: 'Email Notifications',
            type: 'link',
            icon: 'bell-custom'
        },
        {
          state: 'certifications',
          name: 'Certifications',
          type: 'link',
          icon: 'descriptions-custom'
        },
        {
          state: 'product_category',
          name: 'Product Category',
          type: 'link',
          icon: 'cake-chart-custom'
        },
        {

          state: 'company_services',
          name: 'Company Services',
          type: 'link',
          icon: 'tech-custom'
          },
        {
          state: 'manage_admin_users',
          name: 'Manage Users Admin',
          type: 'link',
          icon: 'manage-user-custom'
        },
        {
          state: 'languages',
          name: 'Languages',
          type: 'link',
            icon: 'languages-custom'
        }
    ];
}


var CLIENTMENUITEMS : any = [];
if(versionProduct == 2){
    CLIENTMENUITEMS = [

        {
            state: 'dashboard_client',
            name: 'Dashboards',
            type: 'link',
            icon: 'dashboard-custom'
        },
        {
          state: 'need',
          name: 'Need',
          type: 'link',
          icon: 'search-profile-custom'
        },
        {
            state: 'profile_available',
            name: 'Profile Available',
            type: 'link',
            icon: 'available-profiles-custom'
        },
        {
            state: 'accademy_profiles',
            name: 'Profile Accademy',
            type: 'link',
            icon: 'academy-custom'
        },
        {
          state: 'saved_profiles',
          name: 'Saved Profiles',
          type: 'link',
          icon: 'favourites-custom'
        },
        {
            state: 'collaborations_invite',
            name: 'Collaborations Invite',
            type: 'link',
            icon: 'collaborations-custom'
        },
        {
            state: 'supplier_profile',
            name: 'Supplier List',
            type: 'link',
            icon: 'supplier-custom'
        },
        // {
        //     state: 'supplier',
        //     name: 'Supplier Profile',
        //     type: 'link',
        //     icon: 'supplier-request-custom'
        // },
        // {
        //     state: 'manage_users',
        //     name: 'Manage Users Client',
        //     type: 'link',
        //     icon: 'manage-user-custom'
        // },
        // {
        //     state: 'my_plans',
        //     name: 'My Plans',
        //     type: 'link',
        //     icon: 'plan-custom'
        // },
    ];
} else {

    if(currentUser && currentUser.domain_url != null && currentUser.n_max_supplier != null){
        CLIENTMENUITEMS = [

            {
                state: 'dashboard_client',
                name: 'Dashboards',
                type: 'link',
                icon: 'dashboard-custom'
            },
            {
              state: 'need',
              name: 'Need',
              type: 'link',
              icon: 'search-profile-custom'
            },
            {
                state: 'profile_available',
                name: 'Profile Available',
                type: 'link',
                icon: 'available-profiles-custom'
            },
            {
              state: 'saved_profiles',
              name: 'Saved Profiles',
              type: 'link',
              icon: 'favourites-custom'
            },
            {
                state: 'collaborations_invite',
                name: 'Collaborations Invite',
                type: 'link',
                icon: 'collaborations-custom'
            },
            {
                state: 'supplier_profile',
                name: 'Supplier List',
                type: 'link',
                icon: 'supplier-custom'
            },
            // {
            //     state: 'supplier',
            //     name: 'Supplier Profile',
            //     type: 'link',
            //     icon: 'supplier-request-custom'
            // },
            // {
            //     state: 'manage_users',
            //     name: 'Manage Users Client',
            //     type: 'link',
            //     icon: 'manage-user-custom'
            // }
        ];
    } else {
        if(currentUser && currentUser.simulate_academy == 0 ){
            CLIENTMENUITEMS = [

                {
                    state: 'dashboard_client',
                    name: 'Dashboards',
                    type: 'link',
                    icon: 'dashboard-custom'
                },
                {
                  state: 'need',
                  name: 'Need',
                  type: 'link',
                  icon: 'search-profile-custom'
                },
                {
                    state: 'profile_available',
                    name: 'Profile Available',
                    type: 'link',
                    icon: 'available-profiles-custom'
                },
                {
                  state: 'saved_profiles',
                  name: 'Saved Profiles',
                  type: 'link',
                  icon: 'favourites-custom'
                },
                {
                    state: 'collaborations_invite',
                    name: 'Collaborations Invite',
                    type: 'link',
                    icon: 'collaborations-custom'
                },
                // {
                //     state: 'supplier',
                //     name: 'Supplier Profile',
                //     type: 'link',
                //     icon: 'supplier-request-custom'
                // },
                // {
                //     state: 'manage_users',
                //     name: 'Manage Users Client',
                //     type: 'link',
                //     icon: 'manage-user-custom'
                // }
            ];
        } else if(currentUser && currentUser.simulate_academy == 1){

            CLIENTMENUITEMS = [

                {
                    state: 'dashboard_client',
                    name: 'Dashboards',
                    type: 'link',
                    icon: 'dashboard-custom'
                },
                {
                    state: 'profile_available',
                    name: 'Profile Available',
                    type: 'link',
                    icon: 'available-profiles-custom'
                },
                {
                  state: 'saved_profiles',
                  name: 'Saved Profiles',
                  type: 'link',
                  icon: 'favourites-custom'
                },
                {
                    state: 'collaborations_invite',
                    name: 'Collaborations Invite',
                    type: 'link',
                    icon: 'collaborations-custom'
                },
                // {
                //     state: 'supplier',
                //     name: 'Supplier Profile',
                //     type: 'link',
                //     icon: 'supplier-request-custom'
                // },
                // {
                //     state: 'manage_users',
                //     name: 'Manage Users Client',
                //     type: 'link',
                //     icon: 'manage-user-custom'
                // }
            ];
        }

    }

}

var CLIENTEMPLOYEEMENUITEMS : any = [];

if(versionProduct == 2){
    CLIENTEMPLOYEEMENUITEMS = [
        {
            state: 'dashboard_client',
            name: 'Dashboards',
            type: 'link',
            icon: 'dashboard-custom'
        },
        {
            state: 'need',
            name: 'Need',
            type: 'link',
            icon: 'search-profile-custom'
        },
        {
            state: 'profile_available',
            name: 'Profile Available',
            type: 'link',
            icon: 'available-profiles-custom'
        },
        {
            state: 'accademy_profiles',
            name: 'Profile Accademy',
            type: 'link',
            icon: 'academy-custom'
        },
        {
            state: 'saved_profiles',
            name: 'Saved Profiles',
            type: 'link',
            icon: 'favourites-custom'
        },
        {
            state: 'collaborations_invite',
            name: 'Collaborations Invite',
            type: 'link',
            icon: 'collaborations-custom'
        },
        {
            state: 'supplier_profile',
            name: 'Supplier List',
            type: 'link',
            icon: 'supplier-custom'
        },

    ];
} else {

    if(currentUser && currentUser.domain_url != null && currentUser.n_max_supplier != null){
        CLIENTEMPLOYEEMENUITEMS = [
            {
                state: 'dashboard_client',
                name: 'Dashboards',
                type: 'link',
                icon: 'dashboard-custom'
            },
            {
                state: 'need',
                name: 'Need',
                type: 'link',
                icon: 'search-profile-custom'
            },
            {
                state: 'profile_available',
                name: 'Profile Available',
                type: 'link',
                icon: 'available-profiles-custom'
            },
            {
                state: 'saved_profiles',
                name: 'Saved Profiles',
                type: 'link',
                icon: 'favourites-custom'
            },
            {
                state: 'collaborations_invite',
                name: 'Collaborations Invite',
                type: 'link',
                icon: 'collaborations-custom'
            },
            {
                state: 'supplier_profile',
                name: 'Supplier List',
                type: 'link',
                icon: 'supplier-custom'
            },
            // {
            //     state: 'supplier',
            //     name: 'Supplier Profile',
            //     type: 'link',
            //     icon: 'supplier-request-custom'
            // }

        ];
    } else {
        if(currentUser && currentUser.simulate_academy == 0 ){
            CLIENTEMPLOYEEMENUITEMS = [
                {
                    state: 'dashboard_client',
                    name: 'Dashboards',
                    type: 'link',
                    icon: 'dashboard-custom'
                },
                {
                    state: 'need',
                    name: 'Need',
                    type: 'link',
                    icon: 'search-profile-custom'
                },
                {
                    state: 'profile_available',
                    name: 'Profile Available',
                    type: 'link',
                    icon: 'available-profiles-custom'
                },
                {
                    state: 'saved_profiles',
                    name: 'Saved Profiles',
                    type: 'link',
                    icon: 'favourites-custom'
                },
                {
                    state: 'collaborations_invite',
                    name: 'Collaborations Invite',
                    type: 'link',
                    icon: 'collaborations-custom'
                },
                // {
                //     state: 'supplier',
                //     name: 'Supplier Profile',
                //     type: 'link',
                //     icon: 'supplier-request-custom'
                // }

            ];
        } else if(currentUser && currentUser.simulate_academy == 1){
            CLIENTEMPLOYEEMENUITEMS = [
                {
                    state: 'dashboard_client',
                    name: 'Dashboards',
                    type: 'link',
                    icon: 'dashboard-custom'
                },
                {
                    state: 'profile_available',
                    name: 'Profile Available',
                    type: 'link',
                    icon: 'available-profiles-custom'
                },
                {
                    state: 'saved_profiles',
                    name: 'Saved Profiles',
                    type: 'link',
                    icon: 'favourites-custom'
                },
                {
                    state: 'collaborations_invite',
                    name: 'Collaborations Invite',
                    type: 'link',
                    icon: 'collaborations-custom'
                },
                // {
                //     state: 'supplier',
                //     name: 'Supplier Profile',
                //     type: 'link',
                //     icon: 'supplier-request-custom'
                // }

            ];
        }

    }

}

var SUPPLIERMENUITEMS : any = [];
if(versionProduct == 2){
    SUPPLIERMENUITEMS = [
        {
            state: 'dashboard_supplier',
            name: 'Dashboards',
            type: 'link',
            icon: 'dashboard-custom'
        },
        {
            state: 'company_profile_supplier',
            name: 'Company Profile',
            type: 'link',
            icon: 'company-profile-custom'
        },
        {
            state: 'position_list',
            name: 'Position List',
            type: 'link',
            icon: 'open-position-custom'
        },
        {
            state: 'accademy_profiles',
            name: 'Profile Accademy',
            type: 'link',
            icon: 'academy-custom'
        },
        {
            state: 'employees',
            name: 'Employees',
            type: 'link',
            icon: 'available-profiles-custom'
        },
        {
            state: 'collaborations_request',
            name: 'Collaborations Request',
            type: 'link',
            icon: 'collaborations-custom'
        },
        {
            state: 'customer_profile',
            name: 'Customer Profile',
            type: 'link',
            icon: 'job-custom'
        },
        // {
        //     state: 'manage_users_supplier',
        //     name: 'Manage Users Supplier',
        //     type: 'link',
        //     icon: 'manage-user-custom'
        // },
        {
            state: 'digitize_cv',
            name: 'Digitize CV',
            type: 'link',
            icon: 'cv-custom'
        },

    ];
} else {

    if(currentUser && currentUser.domain_url != null && currentUser.n_max_supplier != null){
        if(currentUser && currentUser.simulate_academy == 0 ){
            SUPPLIERMENUITEMS = [
                {
                    state: 'dashboard_supplier',
                    name: 'Dashboards',
                    type: 'link',
                    icon: 'dashboard-custom'
                },
                {
                    state: 'company_profile_supplier',
                    name: 'Company Profile',
                    type: 'link',
                    icon: 'company-profile-custom'
                },
                {
                    state: 'position_list',
                    name: 'Position List',
                    type: 'link',
                    icon: 'open-position-custom'
                },
                {
                    state: 'employees',
                    name: 'Employees',
                    type: 'link',
                    icon: 'available-profiles-custom'
                },
                {
                    state: 'collaborations_request',
                    name: 'Collaborations Request',
                    type: 'link',
                    icon: 'collaborations-custom'
                },
                {
                    state: 'customer_profile',
                    name: 'Customer Profile',
                    type: 'link',
                    icon: 'job-custom'
                },
                // {
                //     state: 'manage_users_supplier',
                //     name: 'Manage Users Supplier',
                //     type: 'link',
                //     icon: 'manage-user-custom'
                // },
                {
                    state: 'digitize_cv',
                    name: 'Digitize CV',
                    type: 'link',
                    icon: 'cv-custom'
                },

            ];
        } else if(currentUser && currentUser.simulate_academy == 1 ){
            SUPPLIERMENUITEMS = [
                {
                    state: 'dashboard_supplier',
                    name: 'Dashboards',
                    type: 'link',
                    icon: 'dashboard-custom'
                },
                {
                    state: 'company_profile_supplier',
                    name: 'Company Profile',
                    type: 'link',
                    icon: 'company-profile-custom'
                },
                {
                    state: 'employees',
                    name: 'Employees',
                    type: 'link',
                    icon: 'available-profiles-custom'
                },
                {
                    state: 'collaborations_request',
                    name: 'Collaborations Request',
                    type: 'link',
                    icon: 'collaborations-custom'
                },
                {
                    state: 'customer_profile',
                    name: 'Customer Profile',
                    type: 'link',
                    icon: 'job-custom'
                },
                // {
                //     state: 'manage_users_supplier',
                //     name: 'Manage Users Supplier',
                //     type: 'link',
                //     icon: 'manage-user-custom'
                // },
                {
                    state: 'digitize_cv',
                    name: 'Digitize CV',
                    type: 'link',
                    icon: 'cv-custom'
                }

            ];
        }

    } else {
        if(currentUser && currentUser.simulate_academy == 0 ){
            SUPPLIERMENUITEMS = [
                {
                    state: 'dashboard_supplier',
                    name: 'Dashboards',
                    type: 'link',
                    icon: 'dashboard-custom'
                },
                {
                    state: 'company_profile_supplier',
                    name: 'Company Profile',
                    type: 'link',
                    icon: 'company-profile-custom'
                },
                {
                    state: 'position_list',
                    name: 'Position List',
                    type: 'link',
                    icon: 'open-position-custom'
                },
                {
                    state: 'employees',
                    name: 'Employees',
                    type: 'link',
                    icon: 'available-profiles-custom'
                },
                {
                    state: 'collaborations_request',
                    name: 'Collaborations Request',
                    type: 'link',
                    icon: 'collaborations-custom'
                },
                // {
                //     state: 'manage_users_supplier',
                //     name: 'Manage Users Supplier',
                //     type: 'link',
                //     icon: 'manage-user-custom'
                // },
                {
                    state: 'digitize_cv',
                    name: 'Digitize CV',
                    type: 'link',
                    icon: 'cv-custom'
                }

            ];
        } else if(currentUser && currentUser.simulate_academy == 1 ){
            SUPPLIERMENUITEMS = [
                {
                    state: 'dashboard_supplier',
                    name: 'Dashboards',
                    type: 'link',
                    icon: 'dashboard-custom'
                },
                {
                    state: 'company_profile_supplier',
                    name: 'Company Profile',
                    type: 'link',
                    icon: 'company-profile-custom'
                },
                {
                    state: 'employees',
                    name: 'Employees',
                    type: 'link',
                    icon: 'available-profiles-custom'
                },
                {
                    state: 'collaborations_request',
                    name: 'Collaborations Request',
                    type: 'link',
                    icon: 'collaborations-custom'
                },
                // {
                //     state: 'manage_users_supplier',
                //     name: 'Manage Users Supplier',
                //     type: 'link',
                //     icon: 'manage-user-custom'
                // },
                {
                    state: 'digitize_cv',
                    name: 'Digitize CV',
                    type: 'link',
                    icon: 'cv-custom'
                }

            ];
        }

    }

}

var SUPPLIERMANAGEMENMENUITEMS : any = [];
if(versionProduct == 2){
    SUPPLIERMANAGEMENMENUITEMS = [
        {
            state: 'dashboard_supplier',
            name: 'Dashboards',
            type: 'link',
            icon: 'dashboard-custom'
        },
        {
            state: 'position_list',
            name: 'Position List',
            type: 'link',
            icon: 'open-position-custom'
        },
        {
            state: 'accademy_profiles',
            name: 'Profile Accademy',
            type: 'link',
            icon: 'academy-custom'
        },
        {
            state: 'employees',
            name: 'Employees',
            type: 'link',
            icon: 'available-profiles-custom'
        },
        {
            state: 'collaborations_request',
            name: 'Collaborations Request',
            type: 'link',
            icon: 'collaborations-custom'
        },
        {
            state: 'customer_profile',
            name: 'Customer Profile',
            type: 'link',
            icon: 'job-custom'
        },
        {
            state: 'digitize_cv',
            name: 'Digitize CV',
            type: 'link',
            icon: 'cv-custom'
        },
    ];
} else {
    if(currentUser && currentUser.domain_url != null && currentUser.n_max_supplier != null){
        if(currentUser && currentUser.simulate_academy == 0 ){
            SUPPLIERMANAGEMENMENUITEMS = [
                {
                    state: 'dashboard_supplier',
                    name: 'Dashboards',
                    type: 'link',
                    icon: 'dashboard-custom'
                },
                {
                    state: 'position_list',
                    name: 'Position List',
                    type: 'link',
                    icon: 'open-position-custom'
                },
                {
                    state: 'employees',
                    name: 'Employees',
                    type: 'link',
                    icon: 'available-profiles-custom'
                },
                {
                    state: 'collaborations_request',
                    name: 'Collaborations Request',
                    type: 'link',
                    icon: 'collaborations-custom'
                },
                {
                    state: 'customer_profile',
                    name: 'Customer Profile',
                    type: 'link',
                    icon: 'job-custom'
                },
                {
                    state: 'digitize_cv',
                    name: 'Digitize CV',
                    type: 'link',
                    icon: 'cv-custom'
                }
            ];
        } else if(currentUser && currentUser.simulate_academy == 0 ){
            SUPPLIERMANAGEMENMENUITEMS = [
                {
                    state: 'dashboard_supplier',
                    name: 'Dashboards',
                    type: 'link',
                    icon: 'dashboard-custom'
                },
                {
                    state: 'employees',
                    name: 'Employees',
                    type: 'link',
                    icon: 'available-profiles-custom'
                },
                {
                    state: 'collaborations_request',
                    name: 'Collaborations Request',
                    type: 'link',
                    icon: 'collaborations-custom'
                },
                {
                    state: 'customer_profile',
                    name: 'Customer Profile',
                    type: 'link',
                    icon: 'job-custom'
                },
                {
                    state: 'digitize_cv',
                    name: 'Digitize CV',
                    type: 'link',
                    icon: 'cv-custom'
                }
            ];
        }

    } else {
        if(currentUser && currentUser.simulate_academy == 0 ){
            SUPPLIERMANAGEMENMENUITEMS = [
                {
                    state: 'dashboard_supplier',
                    name: 'Dashboards',
                    type: 'link',
                    icon: 'dashboard-custom'
                },
                {
                    state: 'position_list',
                    name: 'Position List',
                    type: 'link',
                    icon: 'open-position-custom'
                },
                {
                    state: 'employees',
                    name: 'Employees',
                    type: 'link',
                    icon: 'available-profiles-custom'
                },
                {
                    state: 'collaborations_request',
                    name: 'Collaborations Request',
                    type: 'link',
                    icon: 'collaborations-custom'
                },
                {
                    state: 'digitize_cv',
                    name: 'Digitize CV',
                    type: 'link',
                    icon: 'cv-custom'
                }
            ];
        } else if(currentUser && currentUser.simulate_academy == 1 ){
            SUPPLIERMANAGEMENMENUITEMS = [
                {
                    state: 'dashboard_supplier',
                    name: 'Dashboards',
                    type: 'link',
                    icon: 'dashboard-custom'
                },
                {
                    state: 'employees',
                    name: 'Employees',
                    type: 'link',
                    icon: 'available-profiles-custom'
                },
                {
                    state: 'collaborations_request',
                    name: 'Collaborations Request',
                    type: 'link',
                    icon: 'collaborations-custom'
                },
                {
                    state: 'digitize_cv',
                    name: 'Digitize CV',
                    type: 'link',
                    icon: 'cv-custom'
                }
            ];
        }


    }

}

let ACCADEMYMENUITEMS:any;

if (versionProduct == 2){
 ACCADEMYMENUITEMS = [
    {
        state: 'dashboard_academy',
        name: 'Dashboards',
        type: 'link',
        icon: 'dashboard-custom'
    },
    {
        state: 'company_profile_supplier',
        name: 'Company Profile',
        type: 'link',
        icon: 'company-profile-custom'
    },
    {
        state: 'employees',
        name: 'Employees',
        type: 'link',
        icon: 'available-profiles-custom'
    },
    {
        state: 'manage_users_supplier',
        name: 'Manage Users Supplier',
        type: 'link',
        icon: 'manage-user-custom'
    }

  ];
}else{
   ACCADEMYMENUITEMS = [
    {
      state: 'dashboard_supplier',
      name: 'Dashboards',
      type: 'link',
      icon: 'dashboard-custom'
    },
    {
      state: 'company_profile_supplier',
      name: 'Company Profile',
      type: 'link',
      icon: 'company-profile-custom'
    },
    {
      state: 'employees',
      name: 'Employees',
      type: 'link',
      icon: 'available-profiles-custom'
    },
    {
      state: 'manage_users_supplier',
      name: 'Manage Users Supplier',
      type: 'link',
      icon: 'manage-user-custom'
    }

  ];
}


const MANAGEMENTACCADEMYMENUITEMS = [
    {
        state: 'dashboard_academy',
        name: 'Dashboards',
        type: 'link',
        icon: 'dashboard-custom'
    },
    {
        state: 'employees',
        name: 'Employees',
        type: 'link',
        icon: 'available-profiles-custom'
    }
];

const FREELANCEMENUITEMS = [
  {
    state: 'dashboard_freelance',
    name: 'Dashboards',
    type: 'link',
    icon: 'dashboard-custom'
  },
  {
    state: 'freelance_profile',
    name: 'Freelance Profile',
    type: 'link',
    icon: 'freelance-profile-custom'
  },
  {
    state: 'freelance_position_list',
    name: 'Position List',
    type: 'link',
    icon: 'open-position-custom'
  },
  {
    state: 'collaborations_request',
    name: 'Collaborations Request',
    type: 'link',
    icon: 'collaborations-custom'
  },
];


const EMPLOYEEMENUITEMS = [
    {
        state: 'working_experience',
        name: 'Working Experience',
        type: 'link',
        icon: 'open-position-custom'
    },
    {
        state: 'upload_cv',
        name: 'Upload CV',
        type: 'link',
        icon: 'upload-cv-custom'
    },

];
let PROFILEACCADEMYMENUITEMS:any;

if (versionProduct == 1) {
   PROFILEACCADEMYMENUITEMS = [
    {
      state: 'student_dashboard',
      name: 'Student Dashboard',
      type: 'link',
      icon: 'dashboard-custom'
    },
    {
      state: 'student_profile',
      name: 'Student Profile',
      type: 'link',
      icon: 'freelance-profile-custom'
    },
    {
      state: 'student_collaborations',
      name: 'Student Collaborations',
      type: 'link',
      icon: 'collaborations-custom'
    },
    {
      state: 'working_experience',
      name: 'Working Experience',
      type: 'link',
      icon: 'open-position-custom'
    },
    {
      state: 'upload_cv',
      name: 'Upload CV',
      type: 'link',
      icon: 'upload-cv-custom'
    },
  ];
} else {
   PROFILEACCADEMYMENUITEMS = [
    // {
    //   state: 'student_dashboard',
    //   name: 'Student Dashboard',
    //   type: 'link',
    //   icon: 'dashboard-custom'
    // },
    // {
    //   state: 'student_profile',
    //   name: 'Student Profile',
    //   type: 'link',
    //   icon: 'freelance-profile-custom'
    // },
    // {
    //   state: 'student_collaborations',
    //   name: 'Student Collaborations',
    //   type: 'link',
    //   icon: 'collaborations-custom'
    // },
    {
      state: 'working_experience',
      name: 'Working Experience',
      type: 'link',
      icon: 'open-position-custom'
    },
    {
      state: 'upload_cv',
      name: 'Upload CV',
      type: 'link',
      icon: 'upload-cv-custom'
    },
  ];
}

@Injectable()
export class HorizontalMenuItems {
    getMenuitem(): Menu[] {

        let currentUser = JSON.parse(sessionStorage.getItem("loginUser")!);
        var user_menus: any;
        if (currentUser.role == "Administrator") {
            user_menus = MENUITEMS;
        } else if (currentUser.role == "Client") {
            user_menus = CLIENTMENUITEMS;
        } else if (currentUser.role == "Supplier") {
            user_menus = SUPPLIERMENUITEMS;
        } else if (currentUser.role == "Supplier Employees") {
            user_menus = EMPLOYEEMENUITEMS;
        } else if (currentUser.role == "Procurement Specialist") {
            user_menus = CLIENTEMPLOYEEMENUITEMS;
        } else if(currentUser.role == "Supplier Management"){
            user_menus = SUPPLIERMANAGEMENMENUITEMS
        } else if(currentUser.role == "Accademy"){
            user_menus = ACCADEMYMENUITEMS
        } else if(currentUser.role == "Management Accademy"){
            user_menus = MANAGEMENTACCADEMYMENUITEMS
        } else if(currentUser.role == "Profile Accademy"){
            user_menus = PROFILEACCADEMYMENUITEMS
        } else if (currentUser.role == "Admin Management") {
            user_menus = ADMINMENUITEMS;
        } else if (currentUser.role == "Freelancer") {
          user_menus = FREELANCEMENUITEMS;
        }


        return user_menus;
    }
}
