import { Component, OnInit } from '@angular/core';
import { AppComponentService } from './app.component.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
export let browserRefresh = false;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  sharedTitle: any;
  public static modalSessionEnd: number = 0;
  manageUserBlock: any;
  discoverCompatibleProfileBlock: any;
  timeMaterialBlock: any;
  fixedPriceBlock: any;
  showEstimateBlock: any;
  hireBlock: any;
  chatBlock: any;
  state: any;
  globalState: any;
  planStatus: any;
  idPlanCurrent: any;
  is_old_plan: any;
  countPosition: any;
  max_position: any;
  transactionFee: any;
  namePlanCurrent: any;
  freePlan: any;
  getPlanStatusRes: any;
  currentDocStatus: any;
  logoSpinner: any = 'assets/images/new-card-icons/loading-logo-proc.svg';
  loginImage:any ='assets/images/new-card-icons/intermezzo.svg'
  logoPlatform:any = 'assets/images/new-card-icons/logo-negative.svg'
  doneInit:any=false;
  doneRouter:any=false;
  logoButton:any = 'assets/images/new-card-icons/logo-button-timeflow.svg';
  currentUser: any;
  subscription: Subscription;
  isFromEmail:any=false;

  constructor(private route: ActivatedRoute,private router: Router,private sanitization: DomSanitizer, private service: AppComponentService) {
   let subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if(this.doneInit == false){
          this.getCurrentStyle();
          this.doneRouter = true;
        }
      }
  });
  this.subscription = router.events.subscribe((event) => {
    if (event instanceof NavigationStart) {
      browserRefresh = !router.navigated;
    }
});
  }

  ngOnInit() {
    this.getCurrentStyle();

    this.doneInit = true;
    console.log('init app');
    this.currentUser = JSON.parse(sessionStorage.getItem("loginUser")!);
  }
  cleanURL(oldURL: any) {
    return this.sanitization.bypassSecurityTrustResourceUrl(oldURL);
  }
  async getCurrentStyle() {
    let toSendRef:any = window.location.href;
    this.route.queryParams.subscribe(params => {
      this.isFromEmail = params.is_email;
      if (params.is_email) {
        toSendRef = null
      }
    })
    await this.service.getBrandColors(toSendRef).then(res => {
      const path = window.location.hostname;
      if (res.success) {
        this.logoSpinner = this.cleanURL(res.data.logoSpinner.extension +';'+ res.data.logoSpinner.base64);
        this.logoButton = this.cleanURL(res.data.logoButton.extension  +';'+ res.data.logoButton.base64);
        this.logoPlatform = res.data.logoPlatform.extension  +';'+ res.data.logoPlatform.base64;
        console.log(res);

        let brandPrincipalColor = res.data.colors.brandPrincipalColor;
        let gradientValue1 = res.data.colors.gradientValue1;
        let gradientValue2 = res.data.colors.gradientValue2;

        let colorPageTitle = res.data.colors.colorPageTitle;

        let brandSecondaryColorOne = res.data.colors.brandSecondaryColorOne;
        let brandSecondaryColorTwo = res.data.colors.brandSecondaryColorTwo;
        let brandSecondaryColorThree = res.data.colors.brandSecondaryColorThree;
        let brandSecondaryColorFour = res.data.colors.brandSecondaryColorFour;

        let brandPrincipalColorLight = res.data.colors.brandPrincipalColorLight;
        let brandSecondaryColorOneLight = res.data.colors.brandSecondaryColorOneLight;
        let brandSecondaryColorTwoLight = res.data.colors.brandSecondaryColorTwoLight;
        let brandSecondaryColorThreeLight = res.data.colors.brandSecondaryColorThreeLight;
        let brandSecondaryColorFourLight = res.data.colors.brandSecondaryColorFourLight;

        let brandPrincipalColorLightHover = res.data.colors.brandPrincipalColorLightHover;

        let brandPrincipalColorLighter = res.data.colors.brandPrincipalColorLighter;
        let brandSecondaryColorOneLighter = res.data.colors.brandSecondaryColorOneLighter;
        let brandSecondaryColorThreeLighter = res.data.colors.brandSecondaryColorThreeLighter;

        let brandSecondaryColorOneDark = res.data.colors.brandSecondaryColorOneDark;
        let brandPrincipalColorDark = res.data.colors.brandPrincipalColorDark;
        let brandSecondaryColorThreeDark = res.data.colors.brandSecondaryColorThreeDark;

        let linkBlue = res.data.colors.linkBlue;

        let black = res.data.colors.black;
        let grey = res.data.colors.grey;
        let greyB2 = res.data.colors.greyB2;
        let lightgrey = res.data.colors.lightgrey;
        let greyFo = res.data.colors.greyFo;
        let lighterGrey = res.data.colors.lighterGrey;
        let veryLightGrey = res.data.colors.veryLightGrey;
        let whiteSnow = res.data.colors.whiteSnow;
        let whiteSnowText = res.data.colors.whiteSnowText;
        let blackText = res.data.colors.blackText;

        let excellent = res.data.colors.excellent;
        let veryGood = res.data.colors.veryGood;
        let good = res.data.colors.good;
        let bad = res.data.colors.bad;
        let noData = res.data.colors.noData;
        let buttonSidebarColor = res.data.colors.buttonSidebarColor;
        let colorSpinner = res.data.colors.colorSpinner;
        let brandBackgroundColor = res.data.colors.brandBackgroundColor;
        let spotSidebar =res.data.colors.spotSidebar

        document.documentElement.style.setProperty('--brandPrincipalColor', brandPrincipalColor);
        document.documentElement.style.setProperty('--gradientValue1', gradientValue1);
        document.documentElement.style.setProperty('--gradientValue2', gradientValue2);
        document.documentElement.style.setProperty('--brandBackgroundColor', brandBackgroundColor);
        document.documentElement.style.setProperty('--colorPageTitle', colorPageTitle);

        document.documentElement.style.setProperty('--brandSecondaryColorOne', brandSecondaryColorOne);
        document.documentElement.style.setProperty('--brandSecondaryColorTwo', brandSecondaryColorTwo);
        document.documentElement.style.setProperty('--brandSecondaryColorThree', brandSecondaryColorThree);
        document.documentElement.style.setProperty('--brandSecondaryColorFour', brandSecondaryColorFour);

        document.documentElement.style.setProperty('--brandPrincipalColorLight', brandPrincipalColorLight);
        document.documentElement.style.setProperty('--brandSecondaryColorOneLight', brandSecondaryColorOneLight);
        document.documentElement.style.setProperty('--brandSecondaryColorTwoLight', brandSecondaryColorTwoLight);
        document.documentElement.style.setProperty('--brandSecondaryColorThreeLight', brandSecondaryColorThreeLight);
        document.documentElement.style.setProperty('--brandSecondaryColorFourLight', brandSecondaryColorFourLight);

        document.documentElement.style.setProperty('--brandPrincipalColorLightHover', brandPrincipalColorLightHover);

        document.documentElement.style.setProperty('--brandPrincipalColorLighter', brandPrincipalColorLighter);
        document.documentElement.style.setProperty('--brandSecondaryColorOneLighter', brandSecondaryColorOneLighter);
        document.documentElement.style.setProperty('--brandSecondaryColorThreeLighter', brandSecondaryColorThreeLighter);

        document.documentElement.style.setProperty('--brandSecondaryColorOneDark', brandSecondaryColorOneDark);
        document.documentElement.style.setProperty('--brandPrincipalColorDark', brandPrincipalColorDark);
        document.documentElement.style.setProperty('--brandSecondaryColorThreeDark', brandSecondaryColorThreeDark);

        document.documentElement.style.setProperty('--linkBlue', linkBlue);

        document.documentElement.style.setProperty('--black', black);
        document.documentElement.style.setProperty('--grey', grey);
        document.documentElement.style.setProperty('--greyB2', greyB2);
        document.documentElement.style.setProperty('--lightgrey', lightgrey);
        document.documentElement.style.setProperty('--greyFo', greyFo);
        document.documentElement.style.setProperty('--lighterGrey', lighterGrey);
        document.documentElement.style.setProperty('--veryLightGrey', veryLightGrey);
        document.documentElement.style.setProperty('--whiteSnow', whiteSnow);
        document.documentElement.style.setProperty('--whiteSnowText', whiteSnowText);
        document.documentElement.style.setProperty('--blackText', blackText);

        document.documentElement.style.setProperty('--excellent', excellent);
        document.documentElement.style.setProperty('--veryGood', veryGood);
        document.documentElement.style.setProperty('--good', good);
        document.documentElement.style.setProperty('--bad', bad);
        document.documentElement.style.setProperty('--noData', noData);
        document.documentElement.style.setProperty('--buttonSidebarColor', buttonSidebarColor);
        document.documentElement.style.setProperty('--spotSidebar', spotSidebar);
        document.documentElement.style.setProperty('--colorSpinner', colorSpinner);
      } else {

        document.documentElement.style.setProperty('--brandPrincipalColor', '#334da9');
        document.documentElement.style.setProperty('--gradientValue1', '#212859');
        document.documentElement.style.setProperty('--gradientValue2', '#334da9');

        document.documentElement.style.setProperty('--colorPageTitle', '#334da9');

        document.documentElement.style.setProperty('--brandBackgroundColor', '#F5F8FF');

        document.documentElement.style.setProperty('--brandSecondaryColorOne', '#5eb984');
        document.documentElement.style.setProperty('--brandSecondaryColorTwo', '#f8a71e');
        document.documentElement.style.setProperty('--brandSecondaryColorThree', '#e75f58');
        document.documentElement.style.setProperty('--brandSecondaryColorFour', '#b2b2b2');

        document.documentElement.style.setProperty('--brandPrincipalColorLight', '#c4cef3');
        document.documentElement.style.setProperty('--brandSecondaryColorOneLight', '#98dfb5');
        document.documentElement.style.setProperty('--brandSecondaryColorTwoLight', '#ffca72');
        document.documentElement.style.setProperty('--brandSecondaryColorThreeLight', '#ec9d99');
        document.documentElement.style.setProperty('--brandSecondaryColorFourLight', '#d7d7d7');

        document.documentElement.style.setProperty('--brandPrincipalColorLightHover', '#E6EBFB');

        document.documentElement.style.setProperty('--brandPrincipalColorLighter', '#F5F8FF');
        document.documentElement.style.setProperty('--brandSecondaryColorOneLighter', '#DEF7E8');
        document.documentElement.style.setProperty('--brandSecondaryColorThreeLighter', '#F3D1CF');

        document.documentElement.style.setProperty('--brandSecondaryColorOneDark', '#16994D');
        document.documentElement.style.setProperty('--brandPrincipalColorDark', '#304290');
        document.documentElement.style.setProperty('--brandSecondaryColorThreeDark', '#ce534d');

        document.documentElement.style.setProperty('--linkBlue', '#0000FF');

        document.documentElement.style.setProperty('--black', '#1F112C');
        document.documentElement.style.setProperty('--grey', '#9F9F9F');

        document.documentElement.style.setProperty('--greyB2', '#B2B2B2');
        document.documentElement.style.setProperty('--lightgrey', '#DDDDDD');
        document.documentElement.style.setProperty('--greyFo', '#F0F0F0');
        document.documentElement.style.setProperty('--lighterGrey', '#EBEBEB');
        document.documentElement.style.setProperty('--veryLightGrey', '#F7F7F7');

        document.documentElement.style.setProperty('--whiteSnow', '#fff');
        document.documentElement.style.setProperty('--whiteSnowText', '#fff');
        document.documentElement.style.setProperty('--blackText', '#1F112C');

        document.documentElement.style.setProperty('--excellent', '#1A974B');
        document.documentElement.style.setProperty('--veryGood', '#6ABE8F');
        document.documentElement.style.setProperty('--good', '#F7AD34');
        document.documentElement.style.setProperty('--bad', '#EE5C58');
        document.documentElement.style.setProperty('--noData', '#DDDDDD');
        document.documentElement.style.setProperty('--buttonSidebarColor', '#fff');
        document.documentElement.style.setProperty('--colorSpinner', '#334da9');

      }
    }).catch(error => {
      let that = this
      this.toDataUrl(this.logoSpinner, function(myBase64: any) {
        that.logoSpinner = that.cleanURL(myBase64);
      });
      this.toDataUrl(this.logoButton, function(myBase64: any) {
        that.logoButton = that.cleanURL(myBase64);
      });
      this.toDataUrl(this.logoPlatform, function(myBase64: any) {
        that.logoPlatform = myBase64;
      });

      document.documentElement.style.setProperty('--brandPrincipalColor', '#334da9');
      document.documentElement.style.setProperty('--gradientValue1', '#212859');
      document.documentElement.style.setProperty('--gradientValue2', '#334da9');

      document.documentElement.style.setProperty('--colorPageTitle', '#334da9');

      document.documentElement.style.setProperty('--brandBackgroundColor', '#F5F8FF');

      document.documentElement.style.setProperty('--brandSecondaryColorOne', '#5eb984');
      document.documentElement.style.setProperty('--brandSecondaryColorTwo', '#f8a71e');
      document.documentElement.style.setProperty('--brandSecondaryColorThree', '#e75f58');
      document.documentElement.style.setProperty('--brandSecondaryColorFour', '#b2b2b2');

      document.documentElement.style.setProperty('--brandPrincipalColorLight', '#c4cef3');
      document.documentElement.style.setProperty('--brandSecondaryColorOneLight', '#98dfb5');
      document.documentElement.style.setProperty('--brandSecondaryColorTwoLight', '#ffca72');
      document.documentElement.style.setProperty('--brandSecondaryColorThreeLight', '#ec9d99');
      document.documentElement.style.setProperty('--brandSecondaryColorFourLight', '#d7d7d7');

      document.documentElement.style.setProperty('--brandPrincipalColorLightHover', '#E6EBFB');

      document.documentElement.style.setProperty('--brandPrincipalColorLighter', '#F5F8FF');
      document.documentElement.style.setProperty('--brandSecondaryColorOneLighter', '#DEF7E8');
      document.documentElement.style.setProperty('--brandSecondaryColorThreeLighter', '#F3D1CF');

      document.documentElement.style.setProperty('--brandSecondaryColorOneDark', '#16994D');
      document.documentElement.style.setProperty('--brandPrincipalColorDark', '#304290');
      document.documentElement.style.setProperty('--brandSecondaryColorThreeDark', '#ce534d');

      document.documentElement.style.setProperty('--linkBlue', '#0000FF');

      document.documentElement.style.setProperty('--black', '#1F112C');
      document.documentElement.style.setProperty('--grey', '#9F9F9F');

      document.documentElement.style.setProperty('--greyB2', '#B2B2B2');
      document.documentElement.style.setProperty('--lightgrey', '#DDDDDD');
      document.documentElement.style.setProperty('--greyFo', '#F0F0F0');
      document.documentElement.style.setProperty('--lighterGrey', '#EBEBEB');
      document.documentElement.style.setProperty('--veryLightGrey', '#F7F7F7');

      document.documentElement.style.setProperty('--whiteSnow', '#fff');
      document.documentElement.style.setProperty('--whiteSnowText', '#fff');
      document.documentElement.style.setProperty('--blackText', '#1F112C');

      document.documentElement.style.setProperty('--excellent', '#1A974B');
      document.documentElement.style.setProperty('--veryGood', '#6ABE8F');
      document.documentElement.style.setProperty('--good', '#F7AD34');
      document.documentElement.style.setProperty('--bad', '#EE5C58');
      document.documentElement.style.setProperty('--noData', '#DDDDDD');
      document.documentElement.style.setProperty('--buttonSidebarColor', '#fff');
      document.documentElement.style.setProperty('--colorSpinner', '#334da9');

    });

    this.currentUser = JSON.parse(sessionStorage.getItem("loginUser")!);
    if(this.currentUser == null || this.currentUser == undefined || this.currentUser.length == 0 || this.loginImage == 'assets/images/new-card-icons/intermezzo.svg'){
      await this.service.getLogoCompany().then(res => {
        this.loginImage = res.data.loginImage.extension  +';'+ res.data.loginImage.base64;
        let imageCover = document.getElementsByClassName('id-sfondo')[0];
        if(imageCover != undefined && imageCover != null && imageCover){
          setTimeout(() => {
            imageCover.remove();
            sessionStorage.setItem('keyCover','true');
          }, 500);
        }
      }).catch(error => {
        let that = this
        this.toDataUrl( this.loginImage, function(myBase64: any) {
          that.loginImage = myBase64;
        });
      })
    }else{
      let imageCover = document.getElementsByClassName('id-sfondo')[0];
      if(imageCover != undefined && imageCover != null && imageCover){
          imageCover.remove();
          sessionStorage.setItem('keyCover','true');
      }
    }

  }
   toDataUrl(url:any, callback:any) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
            callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}

}

export function returnToTop(className: string): void {
  const elementList = document.querySelectorAll('.' + className);
  const element = elementList[0] as HTMLElement;
  element.scrollIntoView({ behavior: 'smooth' });
}
export function removeBackRequest(): void {
  let back1 = sessionStorage.getItem('backRequest');
  let back2 = sessionStorage.getItem('backRequestProfileAvailable');
  if (back1) {
    sessionStorage.removeItem('backRequest')
  }
  if (back2) {
    sessionStorage.removeItem('backRequestProfileAvailable')
  }
}
export function removeDoble(arrayToFilter:any,id:any){
  let arrFilter = arrayToFilter.filter((value: { id: any; }, index: any, self: any[]) =>
    index == self.findIndex((t) => (
      t.id == value.id
    ))
  )
  return arrFilter;
}

export function searchComplexObject(searchTerm: string, data: any): boolean {
  const lowerCaseSearchTerm = searchTerm.toLowerCase();
  const visited = new Set(); // Usato per tenere traccia degli oggetti già visitati

  // Funzione ricorsiva per scansionare l'oggetto
  const searchRecursive = (obj: any): boolean => {
    // Evita di entrare in cicli di riferimento
    if (visited.has(obj)) {
      return false; // Se abbiamo già visitato questo oggetto, non lo visitiamo di nuovo
    }
    visited.add(obj);

    // Itera attraverso tutte le chiavi dell'oggetto
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        // Se il valore è una stringa, controlla se include il termine di ricerca
        if (typeof value === 'string') {
          if (value.toLowerCase().includes(lowerCaseSearchTerm)) {
            return true;
          }
        }

        // Se il valore è un numero, convertilo in stringa e controlla
        if (typeof value === 'number') {
          if (value.toString().includes(lowerCaseSearchTerm)) {
            return true;
          }
        }

        // Se il valore è un array, scansiona ogni elemento dell'array
        if (Array.isArray(value)) {
          for (let item of value) {
            if (searchRecursive(item)) {
              return true;
            }
          }
        }

        // Se il valore è un oggetto, chiama ricorsivamente la funzione
        if (typeof value === 'object' && value !== null) {
          if (searchRecursive(value)) {
            return true;
          }
        }
      }
    }
    return false;
  };

  // Inizia la ricerca ricorsiva
  return searchRecursive(data);
}
