import {
  Component,
  OnInit,
  Inject, Optional, DoCheck, Pipe, Injectable, PipeTransform, ChangeDetectorRef
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, Data, NavigationStart, NavigationError } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ThemePalette } from '@angular/material/core';
import { DatePipe } from '@angular/common';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BreadcrumbOpenService } from './breadcrumb-open.service';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { VerticalAppHeaderOpenComponent } from '../vertical-header-open/vertical-header-open.component';
import { TranslateService } from '@ngx-translate/core';
import {
  MatSnackBar, MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { I } from '@angular/cdk/keycodes';
import { Helper } from 'src/helper/helper';
import * as moment from 'moment';
import { SessionExpiredModalComponent } from 'src/app/session-expired-modal/session-expired-modal.component';
import { OpenComponent } from './../open.component';
import { AppComponent } from 'src/app/app.component';

export interface ChipColor {
  name: string;
  color: ThemePalette;
  state: number
}

export interface Page {
  id: number;
  created_date: string;
  deleted: number;
  deleted_date: string;
  description: string;
  name_page: string;
  page_id: number;
  updated_date: string;
  language: number;
  title: number
}

@Pipe({ name: "dateTimeFormatFilter" })
@Injectable()
export class DateTimeFormatPipe implements PipeTransform {
  transform(date: any, format: string): any {
    if (date) {
      return moment(date).format(format);
    }
  }
}


@Component({
  selector: 'app-breadcrumb-open',
  templateUrl: './breadcrumb-open.component.html',
  styleUrls: ['./breadcrumb-open.component.scss'],
  providers: [DateTimeFormatPipe]
})
export class AppBreadcrumbOpenComponent implements OnInit, DoCheck {
  // @Input() layout;
  pageInfo: Data = Object.create(null);
  currentDocStatus: any;

  descriptionPages: Page[] = [];
  showAlert: boolean = false;
  showAlertPlan: boolean = false;
  message: any;
  cbInstance: any;
  availableColors: ChipColor[] = [];
  alertPlan: ChipColor[] = [];
  dateNow: any;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end'; // start, center, end, left, right
  verticalPosition: MatSnackBarVerticalPosition = 'top'; // top, bottom
  planStatus: any;
  token: any;
  isLoadingResultsPlans = false;
  contRequest = 0;
  date_for_check: any;
  currentRoute: any;
  versionProduct: any;
  logo: any = 'assets/images/new-card-icons/loading-logo-proc.svg'
  countModaleExpired = 0;
  limitations: any = [];
  limitationsArray: any = [];
  manageUserBlock: any;
  discoverCompatibleProfileBlock: any;
  timeMaterialBlock: any;
  fixedPriceBlock: any;
  showEstimateBlock: any;
  hireBlock: any;
  chatBlock: any;
  state: any;
  globalState: any;



  constructor(
    private http: HttpClient,
    private _helper: Helper,
    private translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private _service: BreadcrumbOpenService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private _snackBar: MatSnackBar,
    private dateFormat: DateTimeFormatPipe,
    private openComponent: OpenComponent,
    private cdRef: ChangeDetectorRef,
    public appComponent: AppComponent,
  ) {

    this.matIconRegistry.addSvgIcon(
      `info-fixed`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/topbr-icons/info-fixed.svg")
    );

    this.currentRoute = "";
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
      }

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        // this.currentRoute = event.url;
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
      }
    });

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter(route => route.outlet === 'primary'))
      .pipe(mergeMap(route => route.data))
      .subscribe(event => {
        this.pageInfo = event;
      });

    this.dateNow = new Date();

    this.token = JSON.parse(sessionStorage.getItem("loginUserToken")!);

    this.logo = appComponent.logoSpinner
    console.log(this.logo);


  }
  closeModal() {
    this.showAlertPlan = false;
  }
  ngAfterViewChecked(): void {
    this.manageUserBlock = this.openComponent.manageUserBlock
    this.discoverCompatibleProfileBlock = this.openComponent.discoverCompatibleProfileBlock
    this.timeMaterialBlock = this.openComponent.timeMaterialBlock
    this.fixedPriceBlock = this.openComponent.fixedPriceBlock
    this.showEstimateBlock = this.openComponent.showEstimateBlock
    this.hireBlock = this.openComponent.hireBlock
    this.chatBlock = this.openComponent.chatBlock
    this.state = this.openComponent.state
    this.globalState = this.openComponent.globalState
    ////console.log(this.globalState);
    this.cdRef.detectChanges();
  }


  checkForModal() {

  }




  ngOnInit() {
    this.versionProduct = JSON.parse(sessionStorage.getItem("versionProduct")!);
    // if(this.versionProduct == 2){
    //   this.logo = 'assets/images/new-card-icons/loading-logo.svg';
    // } else if(this.versionProduct == 1){
    //   if (window.location.host == "procurement.timeflow.it") {
    //     this.logo = 'assets/images/new-card-icons/loading-logo.svg';
    //   } else if (window.location.host == "whitelabel.daytrend.it" ) {
    //     this.logo = 'assets/images/new-card-icons/loading-logo.svg';
    //   } else if (window.location.host == "procurement.daytrend.it" ) {
    //     this.logo = 'assets/images/new-card-icons/loading-logo.svg';
    //   } else if (window.location.host == "dev.mkt.daytrend.it" ) {
    //     this.logo = 'assets/images/new-card-icons/loading-logo.svg';
    //   } else {
    //     this.logo = 'assets/images/new-card-icons/loading-logo-proc.svg';
    //   }
    // }



  }

  changeView(element: number) {
    if (element == 4) {
      this.router.navigate(['/company_profile']);
    } else if (element == 5) {
      this.router.navigate(['/my_plans']);
    } else if (element == 6) {
      this.openCheckout(8, 'piano-fornitori-cost')
    }
  }

  getFormUrlEncoded(toConvert: any) {
    const formBody = [];
    for (const property in toConvert) {
      const encodedKey = encodeURIComponent(property);
      if (toConvert[property]) {
        const encodedValue = encodeURIComponent(toConvert[property]);
        formBody.push(encodedKey + '=' + encodedValue);
      }
    }
    return formBody.join('&');
  }

  refreshSubscription(plan_id: any, date_for_check: any) {
    setTimeout(() => {
      this.showAlertPlan = false;
      this.isLoadingResultsPlans = true;
      //console.log('1');
      this.getCheckSubscriptionIsUpdated(plan_id, date_for_check)
    }, 1000);
  }

  ngDoCheck(): void {

    var weebokFunction = sessionStorage.getItem('weebook-function');
    if (weebokFunction == 'true') {
      this.isLoadingResultsPlans = false;
      //console.log('2');
      sessionStorage.removeItem("weebook-function");
    }

  }

  getCheckSubscriptionIsUpdated(plan_id: any, date_for_check: any) {



  }

  openCheckout(plan_id: any, name_plan_charge_bee: any) {

    if (window.location.host == 'marketplace.timeflow.it') {
      this.cbInstance = (<any>window)['Chargebee'].init({
        site: "timeflow"
      });
    } else {
      this.cbInstance = (<any>window)['Chargebee'].init({
        site: "timeflow-test"
      });
    }

    this.cbInstance.openCheckout({
      hostedPage: () => {

        let obj = {
          plan_id: plan_id,
          plan_charge: name_plan_charge_bee,
        }

        return this.http.post(this._helper.getBaseUrl() + 'chargebee/openCheckoutSubscription', this.getFormUrlEncoded(obj), { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': `Bearer ${this._helper.getUserConfigData().access_token}` }) }).toPromise();
      },
      loaded: () => {
        //console.log("checkout opened");
        const format1 = "YYYY-MM-DD HH:mm:ss"
        var date2 = new Date();
        this.date_for_check = moment(date2).format(format1);
      },
      error: (error: any) => {

        if (error.status == 401) {


        } else {
          this.manageError();
        }



      },
      close: () => {
        //console.log("checkout closed");
        this.refreshSubscription(plan_id, this.date_for_check);

      },
      success: (hostedPageId: any) => {

        //console.log(hostedPageId);
      },
      step: (value: any) => {
        //console.log(value);
      }
    });
  }


  openDialog(title: any) {
    let location = window.location.href;
    let locationSplit = location.split('/');
    let locationFinal = '';
    if (title == 'Dashboards' || title == 'Supplier Profile') {
      locationFinal = locationSplit[locationSplit.length - 1];
    } else {
      locationFinal = title;
    }

    var description;
    var title;
    this.descriptionPages = JSON.parse(sessionStorage.getItem("descriptionPages")!);


    var obj = {
      'description': description,
      'title': title
    }

    const dialogRef = this.dialog.open(BreadCrumbDialogOpenContent, {
      data: obj
    });

  }

  manageError() {

  }

}

@Component({
  templateUrl: 'dialog-content-open.html',
  styleUrls: ['./dialog-content-open.scss']
})
export class BreadCrumbDialogOpenContent {

  description: any;


  constructor(public dialogRef: MatDialogRef<BreadCrumbDialogOpenContent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Page) {
    this.description = data;
    setTimeout(() => {
      let modal = document.getElementsByClassName('custom-modal')[0];
      let modalBase = document.getElementsByClassName('mat-dialog-container')[0];
      modal.setAttribute('style', 'opacity:1');
      modalBase.setAttribute('style', 'display:block');
    }, 400);
  }


  closeDialog() {
    let modalBase = document.getElementsByClassName('mat-dialog-container')[0];
    modalBase.setAttribute('style', 'display:none');
    this.dialogRef.close({ event: 'Cancel' });
  }


}

