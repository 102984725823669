import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";


import { MenuItems } from '../../../shared/menu-items/menu-items';
import { HorizontalMenuItems } from '../../../shared/menu-items/horizontal-menu-items';
import { OpenComponentLayoutServices } from './vertical-sidebar-open.service';
import {
  MatSnackBar, MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SessionExpiredModalComponent } from 'src/app/session-expired-modal/session-expired-modal.component';
import { AppComponent, removeBackRequest } from 'src/app/app.component';
import { CompanyDialogContent } from 'src/app/company/company.component';
import { OpenComponent } from './../open.component';
import { E } from '@angular/cdk/keycodes';
import { VerticalAppHeaderOpenComponent } from '../vertical-header-open/vertical-header-open.component';
import { NgxPendoService } from 'ngx-pendo';
export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}

@Component({
  selector: 'app-vertical-sidebar-open',
  templateUrl: './vertical-sidebar-open.component.html',
  styleUrls: ['./vertical-sidebar-open.scss']
})

export class VerticalAppSidebarOpenComponent implements OnDestroy, OnInit, DoCheck {
  activeMenu: any = "Dashboards";
  navbarStretch: any = false;
  sidebarWidth: any;
  mainWidth: any;
  sideIcons: any;
  sidebarElements: any;
  interval: any;
  sidebarContent: any;
  state: any;
  countProjects: any;
  countPosition: any;
  max_position: any;
  max_project: any;
  countModaleExpired = 0;
  idPlanCurrent: any;
  public config: PerfectScrollbarConfigInterface = {};
  mobileQuery: MediaQueryList;

  counterListRequest: any;
  counterListInvite: any;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end'; // start, center, end, left, right
  verticalPosition: MatSnackBarVerticalPosition = 'top'; // top, bottom

  private _mobileQueryListener: () => void;
  status = true;

  itemSelect: number[] = [];
  parentIndex = 0;
  childIndex = 0;
  textLogo: any;
  logo: any;
  sidebarImplementations: any = document.getElementsByClassName("triangle-custom");
  versionProduct: any;
  currentDocStatus: any;
  dateNow: any;
  limitDateDoc: any;
  expiredDate = false;
  planStatus: any;
  responsiveWidth = window.innerWidth;
  user_menus: any;
  functions: any;
  viewButtonAddProject = false;
  viewButtonAddProfile = false;
  viewButtonPositionTimeMaterial = false;
  viewButtonPositionFixedPrice = false;
  companyCounter: any = 0
  servicesCounter: any = 0
  subMenuOpen = false
  token: any;
  @Output() onSelectedMenuItem = new EventEmitter<boolean>();
  counterServices: any;
  isEsprinet = false;
  url: any
  returned: any = '';
  sharedTitle: any;
  manageUserBlock: any;
  discoverCompatibleProfileBlock: any;
  timeMaterialBlock: any;
  fixedPriceBlock: any;
  showEstimateBlock: any;
  hireBlock: any;
  chatBlock: any;
  globalState: any;
  is_old_plan: any;
  transactionFee: any;
  namePlanCurrent: any;
  freePlan: any;
  getPlanStatusRes: any;

  logoSpin = 'assets/images/new-card-icons/loading-logo-proc.svg';
  isLoadingResults: any = false;
  type_version: any;
  type_caller: any;
  sidebarToSee: any = 1;
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
    media: MediaMatcher,
    private cd: ChangeDetectorRef,
    private _service: OpenComponentLayoutServices,
    public menuItems: HorizontalMenuItems,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public openComponent: OpenComponent,
    public _openComponentLayoutServices: OpenComponentLayoutServices,
    public component_1: VerticalAppHeaderOpenComponent,
    protected ngxPendoService: NgxPendoService,
    public father: OpenComponent,
    public appComponent: AppComponent,
  ) {

    setTimeout(() => {
      this.sidebarWidth = document.getElementsByClassName("mat-sidenav")[0];
      this.sidebarContent = document.getElementsByClassName("mat-nav-list")[0];
      this.mainWidth = document.getElementsByClassName("mat-drawer-content")[0];
      this.sideIcons = document.getElementsByClassName("mat-icon");
      this.sidebarElements = document.getElementsByClassName("sidebar-elements");

    }, 3000);


    // //console.log('functions');
    // //console.log(this.functions);
    // //console.log('end functions');


    if (this.functions && this.functions.length != 0) {
      for (let i = 0; i < this.functions.length; i++) {
        if (this.functions[i]['state'] == 'add_project') {
          if (this.functions[i]['status'] == 1) {
            this.viewButtonAddProject = true;
          } else {
            this.viewButtonAddProject = false;
          }
        }
        if (this.functions[i]['state'] == 'add_profile') {
          if (this.functions[i]['status'] == 1) {
            this.viewButtonAddProfile = true;
          } else {
            this.viewButtonAddProfile = false;
          }
        }
        if (this.functions[i]['state'] == 'position_list_t_m') {
          if (this.functions[i]['status'] == 1) {
            this.viewButtonPositionTimeMaterial = true;
          } else {
            this.viewButtonPositionTimeMaterial = false;
          }
        }
        if (this.functions[i]['state'] == 'position_list_f_p') {
          if (this.functions[i]['status'] == 1) {
            this.viewButtonPositionFixedPrice = true;
          } else {
            this.viewButtonPositionFixedPrice = false;
          }
        }
      }
    } else {

    }



    // maticon add custom icons
    this.matIconRegistry.addSvgIcon(
      "client-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/client.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "collaborations-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/collaborations.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "company-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/company.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "cv-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/cv.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "dashboard-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/dashboard.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "employee-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/employee.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "job-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/job.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "plan-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/plan.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "search-profile-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/search-profilew.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "supplier-request-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/supplier-request.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "supplier-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/supplier.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "tech-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/tech.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "manage-user-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/manage-resources.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "available-profiles-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/available-profiles.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "descriptions-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/descriptions.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "profile-review-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/profile-review.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "bell-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/bell.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "company-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/company.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "search-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/search.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "position-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/position.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "open-position-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/business-bag.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "cake-chart-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/cake-chart.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "upload-cv-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/upload.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "favourites-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/new-card-icons/saved-profile-white.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "academy-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/academy.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "languages-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/Language_White.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "freelance-profile-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/new-card-icons/freelance-white.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "search-supplier",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/cerca-fornitori.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "metrics",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/metrics.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "search-services",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/cerca-servizi-admin-white.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "services_list",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/servizi-admin-white.svg")
    );

    this.versionProduct = JSON.parse(sessionStorage.getItem("versionProduct")!);
    this.url = window.location.hostname;


    this.logoSpin = appComponent.logoSpinner
    this.logo = appComponent.logoPlatform

    console.log(this.logoSpin);

    // if (this.versionProduct == 2) {
    //   this.logoSpin = 'assets/images/new-card-icons/loading-logo.svg';
    // } else if (this.versionProduct == 1) {
    //   if (window.location.host == "procurement.timeflow.it") {
    //     this.logoSpin = 'assets/images/new-card-icons/loading-logo.svg';
    //   } else if (window.location.host == "whitelabel.daytrend.it") {
    //     this.logoSpin = 'assets/images/new-card-icons/loading-logo.svg';
    //   } else if (window.location.host == "procurement.daytrend.it") {
    //     this.logoSpin = 'assets/images/new-card-icons/loading-logo.svg';
    //   } else if (window.location.host == "dev.mkt.daytrend.it") {
    //     this.logoSpin = 'assets/images/new-card-icons/loading-logo.svg';
    //   } else if (window.location.hostname.toLowerCase().includes('esprinet')) {
    //     this.logoSpin = 'assets/images/new-card-icons/esprinet-logo-spin.svg';
    //   } else {
    //     this.logoSpin = 'assets/images/new-card-icons/loading-logo-proc.svg';
    //   }
    // }

    // if (this.versionProduct == 2) {
    //   this.logo = 'assets/images/new-card-icons/logo-negative.svg';
    //   if (window.location.hostname.toLowerCase().includes('esprinet')) {
    //     this.logo = 'assets/images/new-card-icons/esprinet-logo.svg';
    //   }
    // } else if (this.versionProduct == 1) {
    //   if (window.location.host == "procurement.timeflow.it") {
    //     this.logo = 'assets/images/new-card-icons/logo-negative.svg';
    //   } else if (window.location.host == "whitelabel.daytrend.it") {
    //     this.logo = 'assets/images/new-card-icons/logo-negative.svg';
    //   } else if (window.location.host == "procurement.daytrend.it") {
    //     this.logo = 'assets/images/new-card-icons/logo-negative.svg';
    //   } else if (window.location.host == "dev.mkt.daytrend.it") {
    //     this.logo = 'assets/images/new-card-icons/logo-negative.svg';
    //   } else if (window.location.hostname.toLowerCase().includes('esprinet')) {
    //     this.logo = 'assets/images/new-card-icons/esprinet-logo.svg';
    //   } else {

    //   }
    // } else {
    //   this.logo = 'assets/images/new-card-icons/logo-negative.svg'
    // }





    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    setTimeout(() => {
      this.textLogo = document.getElementsByClassName("text-logo")[0];
    }, 1);

    let routel = window.location.href
    if (routel.includes('redirect_token')) {
      const myArray = routel.split("/");
      console.log(myArray);
      const n = 3;
      const result = myArray.slice(-n);
      console.log(result);
      let lastRes = result[2].split("?")
      let LastRes: any = lastRes.slice(0, 1)
      let sidebar: any;
      let type: any;
      let utm_campaign: any;
      let utm_medium: any;
      let utm_source: any;
      let inside: any;
      let language: any;
      let company_id: any;
      let employee_id: any;
      let mode_operation: any;

      this.route.queryParams.subscribe(params => {
        sidebar = params.sidebar;
        type = params.type;
        utm_campaign = params.utm_campaign;
        utm_medium = params.utm_medium;
        utm_source = params.utm_source;
        inside = params.inside;
        language = params.language;
        company_id = params.company_id;
        employee_id = params.employee_id;
        mode_operation = params.mode_operation;

      })

      if (sidebar == 0 || sidebar == 1 || sidebar == '1' || sidebar == '0') {
        this.sidebarToSee = sidebar;
      }

      var redirect_url = result[0]
      this.type_version = parseInt(result[1])
      this.type_caller = parseInt(LastRes)
      let firstAccess = sessionStorage.getItem('firstAccessOpenToken')

      firstAccess = 'false'


      if (language == null) {
        language = 'it';
      }

      if (sidebar == 1 && (!firstAccess || firstAccess == null || firstAccess == undefined || firstAccess == 'false') && (!window.location.hostname.toLowerCase().includes('esprinet') && window.location.href.toLowerCase().includes('open'))) {
        this.isLoadingResults = true
        this._service.addToken(redirect_url, this.type_version, this.type_caller, utm_source, utm_medium, utm_campaign, sidebar, language, company_id, employee_id, mode_operation).then(res => {
          let url
          if (redirect_url != 'need_open_create') {
            url = res.data.origin + '/open/' + res.data.urlRedirect + '/' + res.data.token + '/' + res.data.versionProduct + '/' + res.data.typeCaller;
          } else {
            url = res.data.origin + '/open/' + res.data.urlRedirect + '/' + res.data.token + '/' + res.data.versionProduct + '/' + res.data.typeCaller + '?type=' + type
          }
          if (window.location.host == "marketplace.timeflow.it") {
            this.initializePendo(res)
          } else if (window.location.host == "penetration-test.timeflow.it") {
            console.log('noPendo');
          } else if (window.location.host == "daytrend.it") {
            console.log('noPendo');
          } else if (window.location.host == "procurement.timeflow.it") {
            console.log('noPendo');
          } else if (window.location.host == "whitelabel.daytrend.it") {
            console.log('noPendo');
          } else if (window.location.host == "procurement.daytrend.it") {
            console.log('noPendo');
          } else if (window.location.host == "dev.mkt.daytrend.it") {
            console.log('noPendo');
          }


          this.token = res.data.token;
          sessionStorage.setItem('tt', this.token)
          this.getSidebarElements(res.data.token)
          this.isLoadingResults = false
          sessionStorage.setItem('versionProduct', res.data.versionProduct);

          sessionStorage.setItem('firstAccessOpenToken', 'true')
          if (redirect_url == 'complete_detail') {
            this.router.navigate(['/open/' + res.data.urlRedirect + '/' + res.data.token + '/' + company_id + '/null/' + employee_id + '/null/null/null/' + mode_operation], { queryParams: { inside: 1 } })
          } else if (redirect_url == 'need_open_create') {
            this.router.navigate(['/open/' + res.data.urlRedirect + '/' + res.data.token], { queryParams: { type: type, inside: 1 } })
          } else {
            this.router.navigate(['/open/' + res.data.urlRedirect + '/' + res.data.token], { queryParams: { inside: 1 } })
          }


          if (res.data.language) {
            sessionStorage.setItem('language', res.data.language)
            this.component_1.changeInternalLang(res.data.language);
          } else {
            sessionStorage.setItem('language', 'it')
          }

          console.log(url);




        }).catch(error => {

          console.log(error);



        });
      }
    }
  }
  closeSidebar() {
    this.father.sidebarOpened = false
  }
  initializePendo(res: any) {
    this.ngxPendoService.initialize({
      id: res.data.token,
    }, {
      id: res.data.token
    });

  }
  refreshForNeed(state: any) {
    console.log(state);

    let routel = window.location.href
    if (routel.includes('need_open_create') && state == 'need_open_create') {
      window.location.reload()
    }
  }

  ngDoCheck(): void {
    let menuSaved = JSON.parse(sessionStorage.getItem("menuVoice")!);

    this.activeMenu = menuSaved;
    var counter = sessionStorage.getItem('refresh-collab');

    if (sessionStorage.getItem('verifyBtn') == 'true') {

      sessionStorage.removeItem('verifyBtn');
    }
  }
  ngOnInit(): void {
    let routel = window.location.href

    if (!routel.includes('redirect_token') && (!this.user_menus || this.user_menus == undefined || this.user_menus == null)) {
      this.token = sessionStorage.getItem('tt');
      /* GC - Scommentare se si vuole la sidebar popolata in un'altra sessione (al momento rimane vuota) */
      // if(!this.token){
      //   setTimeout(() => {
      //     this.token = this.token = sessionStorage.getItem('tt') ?? this.openComponent.token;
      //     console.log(this.token)
      //     this.getSidebarElements(this.token)
      //   }, 4000);
      // } else {
      this.getSidebarElements(this.token)
      // }
    }
  }


  getSidebarElements(token: any) {
    console.log(token);

    this._openComponentLayoutServices.getSidebar(token).then(res => {
      if (res.success) {
        console.log(res.data)
        var OPENMENUITEMS: any = [];
        let elements = res.data

        for (let element of elements) {
          console.log(element)
          let state;
          if (element.state == 'need') {
            state = "need_open_create"
          } else {
            state = element.state
          }
          console.log(state)
          OPENMENUITEMS.push({
            state: state,
            name: element.name,
            type: element.type,
            icon: element.icon
          })
        }

        console.log(OPENMENUITEMS)


        this.user_menus = OPENMENUITEMS;

      }
    }).catch(error => {
      if (error.status == 401) {
        this.openComponent.refreshToken(1);

      } else {
        this.manageError();
      }

    });

  }

  getTokenReq() {

  }



  loadData() {

  }

  getCounterListInvite() {

  }




  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    sessionStorage.removeItem('firstAccessOpenToken')
    sessionStorage.setItem('firstAccessOpenToken', 'false')
  }

  manageError() {

    var errorMessage = this.translate.translations.it.ErrorServer;

    this._snackBar.open(errorMessage, '', {
      duration: 5000,
      panelClass: ['danger-snackbar'],
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,

    });
  }

  changeCreate(element: any) {
    if (element == 'Need') {
      sessionStorage.removeItem('menuVoice');
      sessionStorage.setItem('menuVoice', JSON.stringify('Need'));
      sessionStorage.setItem('sourceAddProject', JSON.stringify('Sidebar'));
      this.router.navigate(['/need/create']);
    } else if (element == 'Profile') {
      sessionStorage.removeItem('menuVoice');
      sessionStorage.setItem('menuVoice', JSON.stringify('Employees'));
      sessionStorage.setItem('sourceAddProfile', JSON.stringify('Sidebar'));
      this.router.navigate(['employees/add-employee']);
    }
  }


  setBg(menuitem: any, boolean: boolean) {
    sessionStorage.setItem('menuVoice', JSON.stringify(menuitem));
    this.activeMenu = menuitem;
    this.closeSidebar();
    removeBackRequest();
    localStorage.removeItem('currentSearchQuery')!
    if (this.responsiveWidth <= 992) {
      this.onSelectedMenuItem.emit(boolean);
    }
  }
  openSubMenu() {

  }
  closeSubMenu() {

  }
  isInStep(type: any, menuName: any) {


  }

  setClickedRow(i: number, j: number) {
    this.parentIndex = i;
    this.childIndex = j;
  }
  subclickEvent() {
    this.status = true;
  }
  getCountersEsprinet() {

  }
  scrollToTop() {
    document.querySelector('.page-wrapper')?.scroll({
      top: 0,
      left: 0
    });
  }

}
