<div class="custom-modal">
  <div class="menu-container">
    <div class="title">
      {{('FeedbackInternalExternal.askReviewModal' | translate)}}
    </div>
    <div class="subtitle">
        {{('FeedbackInternalExternal.askReviewModalText' | translate)}}
    </div>

    <form #userForm="ngForm" [formGroup]="form" >
      <div class="content">

          <div class="field-custom">
            <mat-form-field style="width: 45%;">
              <input type="text" maxlength="50" matInput required id="firstname" name="firstname"
                [(ngModel)]="mailObj.firstname"
              [formControl]="form.controls['firstname']" formControlName="firstname"
                placeholder="{{('FeedbackInternalExternal.FirstName' | translate)}}" />
            </mat-form-field>

             <mat-form-field style="width: 45%;">
               <input type="text" maxlength="50" matInput required id="lastname" name="lastname"
                 [(ngModel)]="mailObj.lastname"
                 placeholder="{{('FeedbackInternalExternal.LastName' | translate)}}" formControlName="lastname" [formControl]="form.controls['lastname']" />
             </mat-form-field>
          </div>
           <div class="field-custom">
             <mat-form-field style="width: 45%;">
               <input type="text" maxlength="50" matInput required id="email" name="email"
                 formControlName="email" [(ngModel)]="mailObj.email"
                 placeholder="Email" [formControl]="form.controls['email']"/>
             </mat-form-field>

             <mat-form-field style="width: 45%;">
               <input type="text" maxlength="50" matInput required id="company_name" name="company_name"
                 formControlName="company_name"
                 [(ngModel)]="mailObj.company_name" [formControl]="form.controls['company_name']" placeholder="{{('FeedbackInternalExternal.CompanyName' | translate)}}" />
             </mat-form-field>
           </div>
            <div class="field-custom description-field">
              <mat-form-field>
                <textarea class="text-area-custom" placeholder="{{('FeedbackInternalExternal.EmailText' | translate)}}"
                  [(ngModel)]="mailObj.description_email" [formControl]="form.controls['description_email']"
                  formControlName="description_email"
                  name="description_email" maxlength="700" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5" >
                  </textarea>
                <span
                  class="char-counter">{{!mailObj.description_email ? 0 : mailObj.description_email.length }}/700</span>
              </mat-form-field>
            </div>

        <div class="buttons">
          <button class="button-medium blue-default" (click)="closeDialog('Cancel')">
            {{('FeedbackInternalExternal.Cancel' | translate)}}
          </button>
          <button class="button-medium blue-default " [ngClass]="!form.valid ? 'button-medium inactive-default' : 'button-medium blue-default' "
            type="submit" (click)="!form.valid ? $event.stopPropagation() : sendRequest()">
            {{('FeedbackInternalExternal.Ask' | translate)}}
           </button>
        </div>
      </div>
    </form>
  </div>
